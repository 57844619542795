import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import cx from 'classnames';
import styles from './registry.module.scss';
import Step from '../commonComponents/step';
import { Button } from 'reactstrap'

class RegistryStart extends Component {
    render() {
        const returnURL = localStorage.getItem('IDS_RETURNURL')
        const stepTexts = [
            <span>Tell us about you.</span>,
            <span>Tell us where you work.</span>,
            <span>Set your security info.</span>,
            <span>Verify your email.</span>];
        return (
            <React.Fragment>
                <div className="text-center">
                    <h3>Sign Up </h3>
                    <p className={styles.pt20}>Sign Up to try our amazing Solutions</p>
                </div>
                <div className="text-center">
                    <p className={styles.info}>Register a New Organization</p>
                    <p className={styles.info}>Welcome to get started with a brand new Hexagon SFx account.</p>
                    <p className={cx(styles.info, styles.pt20)}>Once you have set up your account you can start onboarding assets,
                    inviting users, and much much more! Get started with the following steps: </p>
                </div>
                <div className={styles.stepWrap}>
                    <Step stepWidth={320} stepNum={4} stepTexts={stepTexts} />
                </div>
                <div className={styles.buttonMiddle}>
                    <Button
                        type="submit"
                        color="primary"
                        size="sm"
                        onClick={this.props.onGetStarted}
                    >
                        Get Started
                </Button>
                </div>
                <div className="text-center">
                    <p className={styles.font16}>Already have an Account?
                        <Link to={{
                            pathname: '/Account/Login',
                            search: returnURL
                        }}> Sign in</Link>
                    </p>
                </div>

            </React.Fragment>
        )
    }
}

export default withRouter(RegistryStart);

