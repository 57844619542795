import apiService from '../../services/api.service'

export const validateEmailAsync = async (email, opts) => {
    return await apiService.get('/api/users/check/email/' + email, {}, opts);
}

export const sendResetLinkAsync = async (email, clientReturnUri) => {
    return await apiService.put(`/api/user/self/${email}/resetpassword/?clientReturnUri=${encodeURIComponent(clientReturnUri)}`);
}

export const resetPasswordAsync = async (pu, opts) => {
    return await apiService.put(`api/user/self/resetpassword/`, pu, opts);
};