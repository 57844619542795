import React, { Component } from 'react';
import styles from './step.module.scss';
import StepItem from './stepItem.component';
import PropTypes from 'prop-types';

class Step extends Component {

    renderStep = () => {
        const { stepNum, stepTexts, stepActive, stepWidth } = this.props;
        const lineWidth = (stepWidth - 20 * stepNum) / (stepNum - 1);
        let steps = [];
        for (let i = 0; i < stepNum; i++) {
            const item = <StepItem key={i} active={stepActive === 'all' ? true : i === stepActive - 1} showLine={i !== stepNum - 1} lineWidth={lineWidth} >
                {stepTexts && stepTexts[i]}
            </StepItem>;
            steps.push(item);
        }
        return (
            <div className={styles.stepWrap} >
                {steps}
            </div>
        )
    }

    render() {
        const { stepTitle, stepActiveText } = this.props;
        return (
            <React.Fragment>
                {stepTitle && <div className={styles.stepTitle}>{stepTitle}</div>}
                {this.renderStep()}
                {stepActiveText && <div className={styles.stepActiveText}>{stepActiveText}</div>}
            </React.Fragment>
        )
    }
}

Step.propTypes = {
    stepNum: PropTypes.number.isRequired,
    stepActive: PropTypes.number,
    stepWidth: PropTypes.number,
    stepTexts: PropTypes.array,
    stepTitle: PropTypes.string,
    stepActiveText: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ])
}

export default Step;