import React from 'react';
import classnames from 'classnames';
import styles from './BannerUI.module.scss';
import PropTypes from 'prop-types';
import { IconBullhorn, IconClock, IconUpgrade, IconLightning } from '@sfx/icons';

export default function Banner(props) {
  const { className, style, isShow, title, description, showClose, onClose, bannerType, bannerPosition } = props;
  let renderContent = '';
  const bannerConfig = typeHash(bannerType);
  if (isShow) {
    renderContent = (
      <div
        className={classnames(
          styles.banner,
          className,
          bannerConfig.colorClass,
          {
            [styles.topBanner]: bannerPosition === 'top'
          }
        )}
        style={style}
      >
        <div className={styles.icon}>
          {bannerConfig.icon}
        </div>
        <h4>{title}</h4>
        <p>{description}</p>
        {showClose && <span className={styles.close} onClick={e => onClose(e)} />}
      </div>)
  }
  return renderContent;
}

const bannerTypeDict = {
  info: {
    icon: <IconClock />,
    colorClass: styles.info,
  },
  upgrade: {
    icon: <IconUpgrade />,
    colorClass: styles.info,
  },
  warning: {
    icon: <IconLightning />,
    colorClass: styles.warning,
  },
  subUpdate: {
    icon: <IconBullhorn />,
    colorClass: styles.info,
  },
  default: {
    icon: <IconClock />,
    colorClass: styles.info,
  }
};

const bannerTypeEnum = Object.keys(bannerTypeDict);

export function typeHash(type) {
  return bannerTypeDict[type] ? bannerTypeDict[type] : bannerTypeDict.default
}

Banner.defaultProps = {
  showClose: true,
  onClose: () => { }
}

Banner.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  isShow: PropTypes.bool.isRequired,
  title: PropTypes.string,
  description: PropTypes.node,
  bannerPosition: PropTypes.oneOf(['top']),
  bannerType: PropTypes.oneOf(bannerTypeEnum),
  showClose: PropTypes.bool,
  onClose: PropTypes.func
}
