import React from 'react'
import { translations } from '../../utils/translations';
const withLocalization = (WrappedContent) => {
    class HOC extends React.Component {
        state = {
            currentLanguage: localStorage.getItem('forcedLanguage') ? localStorage.getItem('forcedLanguage') : translations.getLanguage()
        }

        translateString = (s) => {
            translations.setLanguage(this.state.currentLanguage);
            if (this.state.currentLanguage === 'xx') {
                return (<span style={{ color: 'yellow' }}>[[{translations[s]}]]</span>)
            } else {
                return translations[s] ? translations[s] : s;
            }
        }

        formatString = (key, vars) => {
            const type = typeof vars;

            if (type === 'undefined' || type === undefined) {
                return key;
            }

            if (type === 'object') {
                vars.unshift(translations[key]);
                return translations.formatString(...vars);
            } else {
                return translations.formatString(translations[key], vars);
            }
        }

        setLanguage = (l) => {
            translations.setLanguage(l);
            localStorage.setItem('forcedLanguage', l);
            this.setState({ currentLanguage: l });
        }

        getLanguage = () => {
            return this.state.currentLanguage;
        }

        render() {
            return (
                <WrappedContent
                    translate={(s) => this.translateString(s)}
                    currentLanguage={this.state.currentLanguage}
                    setLanguage={(l) => this.setLanguage(l)}
                    formatString={(key, vars) => this.formatString(key, vars)}
                    {...this.props}
                />
            );
        }
    }

    return HOC;
}

export default withLocalization;