import React from "react";
const imgSrc = {
  warning: '../images/alert-outlinesvg.svg',
  info: '../images/i.svg'
}
const Alert = (props) => (
  <div className={`alert-wrap  d-flex text-sm ${props.className}`}>
    <div><img src={imgSrc[props.type]} alt="ico" /></div>
    <div className='alert-msg-flex'>
      <div className="alert-msg" style={props.fontStyle}>{props.message}</div>
    </div>
  </div>
)
export default Alert;
