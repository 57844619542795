import React from 'react'
const defaultValue = { user: {}, updateUser: () => { } }
export const UserContext = React.createContext(defaultValue)
export const UserConsumer = UserContext.Consumer;

export default class UserProvider extends React.Component {
    render() {
        const { userState, updateUser } = this.props
        return (
            <UserContext.Provider value={{ ...userState, updateUser }}>{this.props.children}</UserContext.Provider>
        )
    }
}