import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, } from 'reactstrap';
import styles from '../registry.module.scss';
import Step from '../../commonComponents/step';
import registerData from '../register-data';
class InviteSuccess extends Component {
    state = {
        stepActiveText: <><span style={{ fontWeight: 'bold' }}>Company:</span> {registerData.organizationName || localStorage.getItem('orgName') || ''}</>
    }

    onSignIn = () => {
        const signInURL = registerData.signInURL || localStorage.getItem('signInURL');
        if (signInURL) {
            window.location.replace(signInURL);
        } else {
            this.props.history.push({ pathname: '/account/login' });
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className={styles.stepWrap}>
                    <Step stepTitle={'Thank you!'} stepWidth={320} stepNum={4} stepActive={'all'} stepActiveText={this.state.stepActiveText} />
                </div>
                <div className={styles.row}>
                    <p>You have successfully completed the registration process for your organization's Hexagon SFx account! As soon as you receive your confirmation email, you can get started!</p>
                    <div className={styles.buttonGroup} >
                        <a target="_blank" rel="noopener noreferrer" href='https://www.hexagonsfx.com'>Learn More About SFx</a>
                        <Button onClick={this.onSignIn} color="primary" size='sm' >Sign in</Button>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(InviteSuccess);