export const detectIE = () => {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE '); // IE 10 or older
    const trident = ua.indexOf('Trident/'); //IE 11
    return (msie > 0 || trident > 0);
}

export const getIdsClientId = () => {
    if (process.env.REACT_APP_CLIENT_ID) {
        return process.env.REACT_APP_CLIENT_ID;
    } else {
        if (process.env.NODE_ENV === 'development') {
            return "0089ad3237424efdb50f5fd39a0ee52f";
        } else {
            return "702bf0adf39e4434a31472614967013d";
        }
    }
};

export const ErrorCase = {
    NOT_FOUND: 'NOT FOUND'
};