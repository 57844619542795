import apiService from '../api.service'

export const LoginAsync = async (body, opts) => {
    return await apiService.post('api/account/login', body, opts);
};

export const LogoutAsync = async (body, opts) => {
    return await apiService.post('api/account/logout', body, opts);
};

export const AcceptTerms = async (userId, body, opts) => {
    return await apiService.post(`/api/user/self/${userId}/acceptterms`, body, opts);
}

export const getUserProfileAsync = userId => {
    return apiService.get(`/api/user/self/${userId}/profiles`, {}, { catchError: true })
}

export const getSolutionsAsync = userId => apiService.get(`/api/user/self/${userId}/solutions`, {}, { catchError: true });

export const getSourceAsync = (obj, opts) => {
    return apiService.get(`/api/WebElementSource`, obj, opts)
}
