import apiService from '../../services/api.service'

export const RetrieveOperationAsync = async (token, opts) => {
    return await apiService.get(`api/operation/${token}`, null, opts);
};

export const ResetPasswordAsync = async (pu, opts) => {
    return await apiService.put(`api/user/self/resetpassword/`, pu, opts);
};

export const ActivateAccountAsync = async (aa, opts) => {
    return await apiService.put(`api/user/self/activate/`, aa, opts);
};