import { Authentication } from '@sfx/authentication';
import { WebStorageStateStore } from 'oidc-client';
import { getIdsClientId } from './util';

const idsScopes = 'identityserver.full_access';

const authority = sessionStorage.getItem('authority');
const options = {
    // Use following setting if bad network quality
    // silentRequestTimeout :60000
    config: {
        authority: authority,
        client_id: getIdsClientId(),
        scope: `openid profile email platform.${getIdsClientId()} ${idsScopes}`,
        userStore: new WebStorageStateStore({ store: window.localStorage }),
        automaticSilentRenew: false,
        accessTokenExpiringNotificationTime: 180
    },
    registerEvents: {
        onUserLoaded: (user) => {
            localStorage.setItem('IDS_Auth', true)
        },
        onUserUnloaded: () => {
            localStorage.clear()
        },
        onUserSignedOut: () => {
            if (window.location.pathname !== '/Account/Login') {
                window.location = '/Account/Login';
            }
        },
        onAccessTokenExpiring: () => {
            const currentUser = JSON.parse(localStorage.getItem('OIDCUSER'));
            if (currentUser && currentUser.profile && currentUser.profile.KeepSignedIn === true) {
                userManager.signinSilent();
            } else {
                userManager.signOut();
            }
        }
    },
    signinType: 'redirect'
}
const userManager = new Authentication(options)



export default userManager;
