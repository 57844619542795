import React from 'react'
import { Button, FormGroup } from 'reactstrap';
import styles from './contactUs.module.scss';

function ContactRight({ props }) {
  const handleSalesClick = () => {

  }
  const handleCustomerServiceClick = () => {

  }
  return (
    <div className={`col-md-4 pt-4 pl-5 ${styles.contactRight}`}>
      <strong>Hexagon Manufacturing Intelligence</strong><br /><br /><br />
      <p>
        250 Circuit Drive<br />
        North Kingstown<br />
        Rhode Island, 02852<br />
        United States<br />
        T:+1 401,886,2000<br />
        F:+1 401.886.2727<br /><br />
      </p>
      <div className="form-row ml-0">
        <FormGroup >
          <Button type="button" onClick={handleSalesClick} className={`mr-5 mb-1 ${styles.secondaryBtn}`}>{props.translate('sales')}</Button>
          <Button type="button" onClick={handleCustomerServiceClick} className={styles.secondaryBtn}>{props.translate('customerService')}</Button>
        </FormGroup>
      </div>
    </div>
  )
}

export default ContactRight
