import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { FormGroup, FormFeedback, Button, Label, CustomInput, Spinner } from 'reactstrap';
import styles from '../registry.module.scss';
import Step from '../../commonComponents/step'
import Alert from '../../commonComponents/alert'
import PasswordMask from '../../commonComponents/password';
import Notification from "../../commonComponents/notification";
import { validatePassword } from '../../../utils/formValidation'
import registerData from '../register-data'
import { debounce } from 'lodash'
import { registerAsync } from '../register.service'

const errorMessage = <span className={styles.pwdErrorTip}>
    <p>Password should meet requirements:</p>
    <ul>
        <li>8-32 characters in length</li>
        <li>At least one uppercase letter</li>
        <li>At least one lowercase letter</li>
        <li>At least one number</li>
        <li>At least one special character</li>
    </ul>
</span>
class CompleteAccount extends Component {
    componentDidMount() {
        if (registerData.password) {
            this.validateFields('password', registerData.password)
        }
    }
    state = {
        showErrors: null,
        errorMessage: errorMessage,
        password: registerData.password || '',
        verifyPassword: registerData.verifyPassword || '',
        canNotSubmit: true,
        readCheck: registerData.readCheck || false,
        isShowLoading: false,
        canGoback: true
    }
    onChange = e => {
        let { name, value } = e.target
        this.setState({
            [name]: value,
            canNotSubmit: true
        }, () => {
            this.validateFields(name, value);
        })
    }
    onCheck = e => {
        let { name, checked } = e.target
        this.setState({ [name]: checked })
        registerData.readCheck = checked
    }
    validateFields = debounce((name, value) => {
        let validResult;
        switch (name) {
            case 'password':
                validResult = validatePassword(value);
                validResult.message = errorMessage
                if (validResult.valid && this.state.verifyPassword) {
                    validResult = this.confirmPassword(value, this.state.verifyPassword)
                }
                break;
            case 'verifyPassword':
                validResult = validatePassword(value);
                validResult.message = errorMessage
                if (validResult.valid) {
                    validResult = this.confirmPassword(this.state.password, value)
                }
                break;
            default: break;
        }

        this.setState({
            showErrors: !validResult.valid,
            errorMessage: validResult.message,
            canNotSubmit: validResult.valid ? !(this.state.verifyPassword && this.state.password) : true
        })
    }, 1000)
    confirmPassword = (password, verifyPassword) => {
        let validResult = {
            valid: password === verifyPassword,
            message: password === verifyPassword ? '' : 'The passwords you entered do not match. Please try again.'
        }
        return validResult
    }

    back = () => {
        registerData.password = this.state.password;
        registerData.verifyPassword = this.state.verifyPassword
        this.props.onStepChange(3);
    }

    onNext = async () => {
        this.setState({ isShowLoading: true, canNotSubmit: true, canGoback: false });
        registerData.password = this.state.password;
        registerData.verifyPassword = this.state.verifyPassword;
        localStorage.setItem('orgName', registerData.organizationName);
        const data = {
            "surname": registerData.lastName,
            "givenName": registerData.firstName,
            "email": registerData.email,
            "userPhoneCountryCode": registerData.userPhoneCountryCode.code,
            "userPhoneCountryCodeGeonameId": registerData.userPhoneCountryCode.geonameId,
            "mobile": registerData.userPhoneNum,
            "password": registerData.password,
            "orgName": registerData.organizationName,
            "orgAddress": registerData.organizationAddress,
            "orgAddressLine2": registerData.organizationAddressLine,
            "orgZipOrPostcode": registerData.organizationZipCode,
            "orgCity": registerData.organizationCity,
            "orgStateOrProvince": registerData.organizationState ? registerData.organizationState.name : '',
            "orgStateOrProvinceId": registerData.organizationState ? registerData.organizationState.geonameId : '',
            "orgCountry": registerData.organizationCountry.countryName,
            "orgRegion": registerData.organizationCountry.continent,
            "orgCountryId": registerData.organizationCountry.geonameId,
            "orgPhoneCountryCode": registerData.organizationPhoneCountryCode.code,
            "orgPhoneCountryCodeGeonameId": registerData.organizationPhoneCountryCode.geonameId,
            "orgPhoneNumber": registerData.organizationPhoneNumber,
            "ClientId": localStorage.getItem('clientId') !== 'undefined' ? localStorage.getItem('clientId') : ''
        }
        try {
            const res = await registerAsync(data, { catchError: true })
            if (res && res.status === 200) {
                this.setState({ isShowLoading: false });
                this.props.onStepChange(5);
                registerData.signInURL = res.data.signInURL;
                localStorage.setItem('signInURL', res.data.signInURL);
            }

        } catch (err) {
            this.setState({ isShowLoading: false });
            Notification.error({
                message: 'error',
                description: 'Server error!'
            })
        }

    }

    render() {
        const prefixCls = 'IS-'
        return (
            < React.Fragment >
                <div className={styles.stepWrap}>
                    <Step stepTitle={'Complete your Account'} stepWidth={320} stepNum={4} stepActive={3} stepActiveText={<span>Set your <font style={{ fontWeight: 'bold' }}>security information.</font></span>} />
                </div>
                <div className={styles.row}>
                    <FormGroup>
                        <Label for="Password" className={styles.label}>Password
                            {this.state.showErrors && <span className="require">*</span>}
                        </Label>
                        <PasswordMask
                            value={this.state.password}
                            fieldName="password"
                            className={`${prefixCls}input`}
                            placeHolder=' '
                            onChange={this.onChange}
                        />
                        <FormFeedback></FormFeedback>
                    </FormGroup>

                    <FormGroup>
                        <Label for="Verify Password" className={styles.label}>Verify Password
                            {this.state.showErrors && <span className="require">*</span>}
                        </Label>
                        <PasswordMask
                            value={this.state.verifyPassword}
                            fieldName="verifyPassword"
                            className={`${prefixCls}input`}
                            placeHolder=' '
                            onChange={this.onChange}
                        />
                        <FormFeedback></FormFeedback>
                    </FormGroup>
                    {this.state.showErrors && <Alert type='warning' message={this.state.errorMessage}></Alert>}

                    <FormGroup>
                        <Label style={{ display: "flex", marginBottom: '-15px' }}>
                            <CustomInput
                                id='readCheck'
                                type="checkbox"
                                className={`${prefixCls}input`}
                                name='readCheck'
                                checked={this.state.readCheck}
                                onChange={this.onCheck} />
                            <span className={styles.labelSpan}>I have read and agree to the  <a target="_blank" rel="noopener noreferrer" href="https://www.hexagonmi.com/terms">Terms and Conditions</a> of the Hexagon Manufacturing Intelligence Cloud Services Agreement.</span>
                        </Label>
                    </FormGroup>
                    <div className={styles.buttonRight}>
                        <Button onClick={this.back} disabled={!this.state.canGoback} outline size='sm'>Back</Button>
                        <Button onClick={this.onNext} disabled={!this.state.readCheck || this.state.canNotSubmit} color="primary" size='sm'>{this.state.isShowLoading && <Spinner size="sm" color="#fff" />} Verify Email</Button>
                    </div>
                </div>
            </React.Fragment >
        )
    }
}

export default withRouter(CompleteAccount);