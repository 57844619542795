import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { FormGroup, Input, Button, Label, Spinner } from 'reactstrap';
import { debounce } from 'lodash'

import styles from '../registry.module.scss';
import Step from '../../commonComponents/step';
import { validateEmail, validatePhoneNumber } from '../../../utils/formValidation';
import { validateEmailAsync, validateGenericEmailAsync, getCountryPhoneCodeListAsyc } from '../register.service';
import Alert from '../../commonComponents/alert';
import { Select } from '@sfx/react-ui';
import { checkEmailDomainExistenceAsync, sendAccountRegistrationNotificationAsync, validateOrgNameAsync } from '../register.service'
import Notification from "../../commonComponents/notification";
import registerData from '../register-data';


class CreateAccountBasic extends Component {
    state = {
        countryPhoneCodeList: [],
        formValid: false,
        email: registerData.email || '',
        validateEmail: (this.props.persistedState && this.props.persistedState.validateEmail !== undefined) ? this.props.persistedState.validateEmail : false,
        validateEmailMsg: '',
        validateEmailShowError: false,
        confirmEmail: registerData.confirmEmail || '',
        validateConfirmEmail: (this.props.persistedState && this.props.persistedState.validateConfirmEmail !== undefined) ? this.props.persistedState.validateConfirmEmail : false,
        validateConfirmEmailMsg: '',
        phoneNum: registerData.userPhoneNum || '',
        validatephoneNum: false,
        validatephoneMsg: '',
        validatephoneNumShowError: false,
        phoneCountryCode: registerData.userPhoneCountryCode || null,
        firstName: registerData.firstName || '',
        isFirstNameChange: false,
        lastName: registerData.lastName || '',
        isLastNameChange: false,
        nextStep: 2,
        isShowCheckEmailLoading: false,
        showNumberLoading: true,
        isExistedDomain: (this.props.persistedState && this.props.persistedState.isExistedDomain !== undefined) ? this.props.persistedState.isExistedDomain : false,
        messageContent: (this.props.persistedState && this.props.persistedState.messageContent) ? this.props.persistedState.messageContent : '',
        showMessageBox: (this.props.persistedState && this.props.persistedState.showMessageBox !== undefined) ? this.props.persistedState.showMessageBox : false,
        sendBtnDiabled: false,
        requestAccessButtonDisabled: false,
        showNextButton: (this.props.persistedState && this.props.persistedState.showNextButton !== undefined) ? this.props.persistedState.showNextButton : true,
        isFreeProviderEmail: (this.props.persistedState && this.props.persistedState.isFreeProviderEmail !== undefined) ? this.props.persistedState.isFreeProviderEmail : true,
    }

    componentDidMount() {
        this.validForm();
        this.getCountryPhoneCodeListAsyc();
    }

    getCountryPhoneCodeListAsyc = async () => {
        try {
            const res = await getCountryPhoneCodeListAsyc({ catchError: true });
            if (res && res.data) {
                this.setState({ countryPhoneCodeList: res.data, showNumberLoading: false });
            }
        } catch (err) {
            this.setState({ showNumberLoading: false })
        }
    }


    validForm = () => {
        if (registerData.email && (!this.props.persistedState || this.props.persistedState.isExistedDomain === undefined)) {
            this.validateEmail(registerData.email);
        }
        if (registerData.userPhoneNum) {
            this.validatePhone(registerData.userPhoneNum);
        }
    }


    onNext = () => { 
        if (!(this.state.validateEmail && this.state.validatephoneNum) || this.state.isExistedDomain) {
            return;
        }

        if (this.state.isFreeProviderEmail) {
            this.setRegisterData();
            this.props.onStepChange(this.state.nextStep);
            return;
        }
        this.setState({ showNextButton: false });
        this.checkWhetherDomainExistedAsync(this.state.email);
    }

    onEmailChange = (e) => {
        const value = e.target.value;
        this.setState({ email: value, nextStep: 2, validateEmail: false }, () => {
            value && this.validateEmail(value);
        });

    }

    validateEmail = debounce((value) => {
        const validateRes = validateEmail(value);
        let msg = (<div className={'inputErrorTips'}>{value && validateRes.message}</div>);
        this.setState({ validateEmailMsg: msg, validateEmail: false, validateEmailShowError: !validateRes.valid }, async () => {
            if (validateRes.valid) {
                if(this.state.confirmEmail.trim()){
                    this.checkConfirmEmail(value,this.state.confirmEmail);
                }
                const validateGenericEmail = await this.checkGenericEmailAsync(value);
                if (!validateGenericEmail) {
                    msg = (<Alert type='info' message={'Sign up with your corporate domain email. If your organization uses a generic mail server (@gmail, @yahoo, @aol, etc.), this will require a longer manual validation with Hexagon support.'} />);
                    this.setState({ validateEmail: true, validateEmailMsg: msg, nextStep: 7, validateEmailShowError: false, isFreeProviderEmail: true });
                } else {
                    this.setState({ isShowCheckEmailLoading: true, isFreeProviderEmail: false });
                    const valid = await this.checkEmailAsync(value);
                    this.setState({ isShowCheckEmailLoading: false });
                    if (!valid) {
                        let returnURL = localStorage.getItem('IDS_RETURNURL')
                        msg = (<Alert type='warning' message={(<>Email already in use, do you have an SFx account already? <Link to={{
                            pathname: '/Account/Login',
                            search: returnURL
                        }}  >Sign in here.</Link></>)} />);
                        this.setState({ validateEmail: false, validateEmailMsg: msg, validateEmailShowError: true });
                    } else {                    
                        this.setState({ validateEmail: true, validateEmailShowError: false });
                       
                    };

                }

            }
        });
    }, 1000)

    checkEmailAsync = async (value) => {
        try {
            let res = await validateEmailAsync(value, { catchError: true });
            if (res && res.status === 204) {
                return false;
            } else {
                return true;
            }
        } catch (err) {
            return true;
        }

    }

    checkWhetherDomainExistedAsync = async (value) => {
        try {
            let res = await checkEmailDomainExistenceAsync(value, { catchError: true });
            if (res && res.status === 200) {
                this.setState({ isExistedDomain: true }, () => { this.setRegisterData(); });
            } else {
                this.setState({ isExistedDomain: false }, () => { this.GoToNext(); });
            }
        } catch (err) {
            if (err.response && err.response.status === 404) {
                this.setState({ isExistedDomain: false }, () => { this.GoToNext(); });
            } else {
                this.setState({ isExistedDomain: true }, () => { this.setRegisterData(); }
                );
            }
        }
    }

    onConfirmChange = (e) => {
        const value = e.target.value;
        this.setState({ confirmEmail: value });
        const validateRes = validateEmail(value.trim());
        if(!validateRes.valid) {
        const msg = (<div className={'inputErrorTips'}>{validateRes.message}</div>);
            this.setState({validateConfirmEmail:false,validateConfirmEmailMsg:msg})
        } else {
            this.setState({validateConfirmEmail:true,validateConfirmEmailMsg:''},()=>{
                if(value.trim()) {
                    this.checkConfirmEmail(value,this.state.email);
                }
            })
        }
       
    }

    checkConfirmEmail = (value1,value2) => {
        if (value1.trim() !== value2.trim()) {
            const msg = (<div className={'inputErrorTips'}>Those email addresses didn't match.Try again.</div>);
            this.setState({ validateConfirmEmail: false, validateConfirmEmailMsg: msg });
        } else {
            this.setState({ validateConfirmEmail: true, validateConfirmEmailMsg: '' }, () => { this.setState({ isExistedDomain: false, showMessageBox: false, showNextButton: true }); });
        }
    }


    checkGenericEmailAsync = async (value) => {
        try {
            let res = await validateGenericEmailAsync(value, { catchError: true });
            if (res && res.data) {
                if (res.data.isFreeProvider) {
                    return false
                } else {
                    return true
                }
            }
        } catch (err) {
        }


    }

    onPhoneChange = (e) => {
        const value = e.target.value;
        this.setState({ phoneNum: value, validatephoneNum: false }, () => {
            value && this.validatePhone(value);
        })
    }

    validatePhone = debounce((value) => {
        let msg;
        const validateRes = validatePhoneNumber(value);
        const msgDom = (<>Please only use <span className={styles.textcolor}>+</span>, <span className={styles.textcolor}>-</span>, <span className={styles.textcolor}>( )</span>,<span className={styles.textcolor}>.</span> , and  <span className={styles.textcolor}>spaces</span> to divide phone numbers in this section.</>)
        if (validateRes.type === 'special') {
            msg = <Alert type='warning' message={msgDom} />;
        } else {
            msg = (value && validateRes.message && < Alert type='warning' message={msgDom} />);
        }
        if (validateRes.valid) {
            registerData.userPhoneNum = value;
        }

        this.setState({ validatephoneMsg: msg, validatephoneNum: validateRes.valid, validatephoneNumShowError: !validateRes.valid });
    }, 1000)

    onFirstNameChange = (e) => {
        const value = e.target.value;
        this.setState({ firstName: value });
    }

    onLastNameChange = (e) => {
        const value = e.target.value;
        this.setState({ lastName: value })
    }

    onPhoneCountryCodeChange = (item) => {
        registerData.userPhoneCountryCode = item;
        this.setState({ phoneCountryCode: item })
    }

    setRegisterData = () => {
        registerData.email = this.state.email.trim();
        registerData.confirmEmail = this.state.confirmEmail.trim();
        registerData.firstName = this.state.firstName.trim();
        registerData.lastName = this.state.lastName.trim();
    }

    sendAccountRegistrationNotification = async (e) => {
        e.preventDefault();
        let requestType = e.target.id;
        if (requestType === "USERJOININGINEXISTINGORGANIZATION") {
            this.setState({ requestAccessButtonDisabled: true }); 
        } else {
            this.setState({ sendBtnDiabled: true })
        }

        if ((!this.state.showMessageBox || !this.state.messageContent.trim()) && requestType === "CONTACTSFXFORASSISTANCE") {
            return;
        }

        let requestData = {
            "surname": this.state.lastName.trim(),
            "givenName": this.state.firstName.trim(),
            "email": this.state.email.trim(),
            "userPhoneCountryCode": this.state.phoneCountryCode.code,
            "mobile": this.state.phoneNum.trim(),
            "reasonMessage": this.state.messageContent.trim()
        }
         
        try {
            const res = await sendAccountRegistrationNotificationAsync(requestType, requestData, { catchError: true })
            if (res && res.status === 200) {
                let successMsg;
                successMsg = requestType === "USERJOININGINEXISTINGORGANIZATION" ? 'Email was sent to your account administrators to request for your access!'
                    : "Email was sent to SFx Business Development to request for your access!";
        
                this.setState({sendBtnDiabled: this.state.messageContent && this.state.messageContent.trim() ? false : true,
                    requestAccessButtonDisabled: false }, () =>
                {
                    this.props.onStepChange(8, successMsg,
                    {
                        isExistedDomain: this.state.isExistedDomain,
                        messageContent: this.state.messageContent,
                        showMessageBox: this.state.showMessageBox,
                        showNextButton: this.state.showNextButton,        
                        validateConfirmEmail: this.state.validateConfirmEmail,
                        validateEmail: this.state.validateEmail,
                        isFreeProviderEmail: this.state.isFreeProviderEmail
                    })               
                })          
            }
        } catch (err) {
            let errorMsg ;
            errorMsg = err && err.response && err.response.status === 404 ? 'Your Account Administrator might not be Available in the System, Email was not Send, Please Contact SFx Asset Management Administrator!'
                : 'Unknown Error Happened, Please Contact SFx Asset Management Administrator!';
            Notification.error({
                message: 'Unknown Error',
                description: errorMsg
            })
        }
    };

    GoToNext = () => {
        this.setRegisterData();
        this.props.onStepChange(this.state.nextStep)
    }

    scrollToAnchor = () => {
        const anchorElement = document.getElementById("messagebox");
        if (anchorElement) {
            anchorElement.scrollIntoView(false);
        }
    };

    displayMessageBox = async (e) => {
        e.preventDefault();
        this.setState({ showMessageBox: !this.state.showMessageBox }, () => {
           if (this.state.showMessageBox) {
                this.scrollToAnchor();
           }
        });    
    }

    onMessageContentChange = (e) => {
        const value = e.target.value;
        this.setState({ messageContent: value });
        if (value.trim()) {
            this.setState({ sendBtnDiabled: false });
        }
        else {
            this.setState({ sendBtnDiabled: true });
        };
    }

    render() {
        const nextBtnDisabled = !(
            this.state.validateEmail
            && this.state.validateConfirmEmail
            && this.state.firstName.trim()
            && this.state.lastName.trim()
            && this.state.validatephoneNum
            && this.state.phoneCountryCode
            && !this.state.isExistedDomain);

        const disabledRequestAccessButton = !(
            this.state.validateEmail
            && this.state.validateConfirmEmail
            && this.state.firstName.trim()
            && this.state.lastName.trim()
            && this.state.validatephoneNum
            && this.state.phoneCountryCode
            && this.state.isExistedDomain
            && !this.state.requestAccessButtonDisabled);

        const disabledSendBtn = !(
            this.state.validateEmail
            && this.state.validateConfirmEmail
            && this.state.firstName.trim()
            && this.state.lastName.trim()
            && this.state.validatephoneNum
            && this.state.phoneCountryCode
            && this.state.isExistedDomain
            && this.state.messageContent
            && !this.state.sendBtnDiabled);

        return (
            <React.Fragment>
                <div className={styles.stepWrap}>
                    <Step stepTitle={'Create your Account'} stepWidth={320} stepNum={4} stepActive={1} stepActiveText={<span>Tell us about <font style={{ fontWeight: 'bold' }}>you.</font></span>} />
                </div>
                <div className={styles.row}>
                    <FormGroup className={styles.formGroupWrap}>
                        <Label for="email" className={styles.label}>Work Email
                            {!this.state.email.trim() && <span className="require">*</span>}
                        </Label>
                        {this.state.isShowCheckEmailLoading && <Spinner className={styles.spinner} size="sm" color="#fff" />}
                        <Input value={this.state.email} onChange={this.onEmailChange} />
                        {this.state.validateEmailMsg}
                    </FormGroup>
                    <FormGroup className={styles.formGroupWrap}>
                        <Label for="email" className={styles.label}>Confirm Email
                            {!this.state.confirmEmail.trim() && <span className="require">*</span>}
                        </Label>
                        <Input value={this.state.confirmEmail} onChange={this.onConfirmChange} onPaste={(e) => e.preventDefault()}/>
                        {this.state.validateConfirmEmailMsg}
                    </FormGroup>
                    <FormGroup>
                        <Label for="firstName" className={styles.label}>First Name
                        {!this.state.firstName.trim() && <span className="require">*</span>}
                        </Label>
                        <Input value={this.state.firstName} onChange={this.onFirstNameChange} maxLength='50' />
                    </FormGroup>
                    <FormGroup>
                        <Label for="lastName" className={styles.label}>Last Name
                        {!this.state.lastName.trim() && <span className="require">*</span>}
                        </Label>
                        <Input value={this.state.lastName} onChange={this.onLastNameChange} maxLength='50' />
                    </FormGroup>
                    <FormGroup>
                        <Label for="phoneCountryCode" className={styles.label}>Phone Country Code
                        {!this.state.phoneCountryCode && < span className="require">*</span>}
                        </Label>
                        <Select
                            placeholder={''}
                            showFlag={true}
                            renderOption={i => ({
                                label: `(+${i.code} ${i.name} )`,
                                value: i.geonameId,
                                FlagUrl: i.flagUrl
                            })}
                            value={this.state.phoneCountryCode}
                            options={this.state.countryPhoneCodeList}
                            onChange={this.onPhoneCountryCodeChange}
                            isLoading={this.state.showNumberLoading}
                            isDisabled={this.state.showNumberLoading}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="phoneNum" className={styles.label}>Mobile Phone Number
                            {!this.state.phoneNum && < span className="require">*</span>}
                        </Label>
                        <Input value={this.state.phoneNum} maxLength='20' onChange={this.onPhoneChange} />
                        {this.state.validatephoneMsg}
                    </FormGroup>
                    {this.state.isExistedDomain && <div className={styles.options}>
                        <>Good news! Your organization already exists. Click the button to request access from your Admins.
                        </>
                        <div className={styles.buttonGroupMargin}>
                            <Link to="#" id="displaymessagebox" onClick={this.displayMessageBox} >Contact SFx for assistance </Link>
                            <Button id="USERJOININGINEXISTINGORGANIZATION" disabled={disabledRequestAccessButton} onClick={this.sendAccountRegistrationNotification} color="primary" size='sm'>Request Access</Button>
                        </div>
                        </div>}
                    {this.state.showMessageBox && <FormGroup>
                        <Label for="messagecontent" className={styles.label}>Message
                            {!this.state.messageContent.trim() && <span className="require">*</span>}
                        </Label>
                        <div>
                            <Input className={styles.textarea} type="textarea" id="messagebox" maxLength="500" row="6" value={this.state.messageContent} onChange={this.onMessageContentChange} />
                        </div>   
                    </FormGroup>}
                    <div className={styles.buttonRight}>
                        {this.state.showMessageBox && <Button disabled={disabledSendBtn} id="CONTACTSFXFORASSISTANCE" onClick={this.sendAccountRegistrationNotification} color="primary" size='sm'>Send</Button>}
                        {!this.state.showMessageBox && this.state.showNextButton && <Button disabled={nextBtnDisabled} onClick={this.onNext} color="primary" size='sm'>Next</Button>}
                    </div>
                </div>
            </React.Fragment >
        )
    }
}
  
export default withRouter(CreateAccountBasic);