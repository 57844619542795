import "core-js/stable";
import "regenerator-runtime/runtime";
import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.scss';
import App from './App';
import { apiBaseUrl } from './config'
//import registerServiceWorker from './registerServiceWorker';

import { BrowserRouter } from 'react-router-dom';

const render = () => {
    const app = (
        <BrowserRouter>
            <App />
        </BrowserRouter>
    )

    ReactDOM.render(app, document.getElementById('root'));
}
apiBaseUrl.then(() => {
    render()
})

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//registerServiceWorker();
