import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, } from 'reactstrap';
import styles from '../registry.module.scss';
import registerData from '../register-data';
class InviteSuccess extends Component {
    state = {
        stepActiveText: <> <span style={{ fontWeight: 'bold' }}>Email:</span>  {registerData.email || ''} </>,
    }
    onBack = () => {
        this.props.onStepChange(1);
    }
    render() {
        return (
            <React.Fragment>
                <div style={{ marginTop: '10px' }} >
                    <p style={{ fontSize: "1.4rem", marginBottom: '20px', height: '33px', whiteSpace: 'nowrap' }}>Manual Registration Required</p>
                    <p style={{ marginBottom: '15px' }}>{this.state.stepActiveText}</p>
                </div>
                <div className={styles.row}>
                    <p>We cannot match your SFx user account to an organization because you have registered with a generic email.</p>
                    <p>Please contact your orgnization's administrator for an invitation, or contact Hexagon Support for assistance in setting up your account.</p>
                    <div className={styles.buttonGroup} >
                        <a href='https://www.hexagonmi.com/contact-us' target='_bank'>Send a Case Report to Hexagon</a>
                        <Button onClick={this.onBack} outline size='sm' >Back</Button>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(InviteSuccess);