import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Label } from 'reactstrap';
import styles from '../registry.module.scss';
import Step from '../../commonComponents/step';
import registerData from '../register-data';

class CreateAccountInfo extends Component {
    onBack = () => {
        this.props.onStepChange(2);
    }

    onNext = () => {
        this.props.onStepChange(4);
    }

    render() {
        return (
            <React.Fragment>
                <div className={styles.stepWrap}>
                    <Step stepTitle={'Create your Organization'} stepWidth={320} stepNum={4} stepActive={2} stepActiveText={<span>Tell us about where <font style={{ fontWeight: 'bold' }}>you work.</font> </span>} />
                </div>
                <p className={styles.tip}>
                    Look over the information below and make sure it is correct before moving to the next step.
                </p>
                <div>
                    <div>
                        <Label className={styles.label}>Admin User</Label>
                        <p className={styles.info}>{`${registerData.firstName} ${registerData.lastName}`}</p>
                    </div>
                    <div>
                        <Label className={styles.label}>Email</Label>
                        <p className={styles.info}>{registerData.email}</p>
                    </div>
                    <div>
                        <Label className={styles.label}>Mobile Phone Number</Label>
                        <p className={styles.info}>{`(+${registerData.userPhoneCountryCode && registerData.userPhoneCountryCode.code})  ${registerData.userPhoneNum}`}</p>
                    </div>
                    <div>
                        <Label className={styles.label}>Organization Name</Label>
                        <p className={styles.info}>{registerData.organizationName}</p>
                    </div>
                    <div>
                        <Label className={styles.label}>Organization Phone Number</Label>
                        <p className={styles.info}>{`(+${registerData.organizationPhoneCountryCode && registerData.organizationPhoneCountryCode.code})  ${registerData.organizationPhoneNumber}`}</p>
                    </div>
                    <div>
                        <Label className={styles.label}>Organization Address </Label>
                        <p className={styles.info}>{`${registerData.organizationAddress},${registerData.organizationAddressLine ? registerData.organizationAddressLine + ',' : ''} ${registerData.organizationCity}, ${registerData.organizationState ? registerData.organizationState.name + ', ' : ''}${registerData.organizationZipCode} ${registerData.organizationCountry && registerData.organizationCountry.countryName}`}</p>
                    </div>


                </div>
                <div className={styles.buttonRight}>
                    <Button onClick={this.onBack} outline size='sm'>Back</Button>
                    <Button onClick={this.onNext} color="primary" size='sm'>Next</Button>
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(CreateAccountInfo);