import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { Form, FormGroup, Label, Input, Button, Spinner } from 'reactstrap'
import { debounce } from 'lodash'

import Alert from '../commonComponents/alert'
import { validateEmail } from '../../utils/formValidation'
import { sendResetLinkAsync } from './resetPassword.service'
import styles from './index.module.scss'


export default class ResetPasswordStep1 extends Component {
    constructor() {
        super();
        this.validateEmail = debounce(this.validateEmail, 1000);
    }
    state = {
        email: '',
        emailValid: false,
        emailValidateMsg: '',
        showAlert: false,
        isSubmitDisabled: true,
        clientReturnUri: '',
        verifyLoading: false
    }

    validateEmail(key, value) {
        value = value.trim();
        let { valid, message } = validateEmail(value);
        this.afterValidation({ key, value, valid, message });
    }

    afterValidation({ key, value, valid, message }) {
        if (valid) {
            this.setState({
                isSubmitDisabled: false
            })
        } else {
            this.setState({
                isSubmitDisabled: true
            })
        }
        this.setState({
            [key]: value,
            [key + 'Valid']: valid,
            showAlert: !valid,
            [key + 'ValidateMsg']: message
        })
    }

    debounceHandle = e => {
        e.persist();
        this.validateEmail('email', e.target.value);
    };

    onSubmit = (e) => {
        this.setState({ verifyLoading: true })
        let clientReturnUri = localStorage.getItem('clientReturnUrl') !== 'undefined' ? localStorage.getItem('clientReturnUrl') : '';
        e.preventDefault();
        if (this.state.emailValid) {
            sendResetLinkAsync(this.state.email, clientReturnUri)
                .then(res => {
                    if ([200, 204].includes(res.status)) {
                        this.setState({ verifyLoading: false })
                        this.props.history.push('/ResetPassword/step2');
                    }
                })
                .catch(error => { console.dir(error); this.setState({ verifyLoading: false }) });
        }
    }

    render() {
        const returnURL = localStorage.getItem('IDS_RETURNURL')
        const prefixCls = 'IS-'
        return (
            <React.Fragment>
                <div className={styles.header}>Reset Password</div>
                <div className={styles.guide}>Please enter your account email to receive a link allowing you to reset your password</div>
                <Form onSubmit={this.onSubmit}>
                    <FormGroup>
                        <Label>Email</Label>
                        <Input className={`${prefixCls}input`} onChange={this.debounceHandle}></Input>
                    </FormGroup>
                    <div className={`${styles.buttonGroup} ${styles['buttonGroup-flex-wrap']}`}>
                        <Link to={{
                            pathname: '/Account/Login',
                            search: returnURL
                        }}>
                            Return to Sign In
                        </Link>
                        <Button
                            type="submit"
                            color="primary"
                            size="sm"
                            disabled={this.state.isSubmitDisabled || this.state.verifyLoading}
                            className={styles.buttonRight}>
                            {this.state.verifyLoading && <Spinner className={styles.spinner} size="sm" color="#fff" />}
                            Submit
                        </Button>
                    </div>
                </Form>
                {
                    this.state.showAlert && <Alert type='warning' message={this.state.emailValidateMsg}></Alert>
                }
            </React.Fragment>
        )
    }
}