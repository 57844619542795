import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Input, Button, Spinner } from 'reactstrap';
import styles from '../registry.module.scss';
import Step from '../../commonComponents/step';
import Alert from '../../commonComponents/alert';
import { consumeCaptchaAsync, cleanActionLinksAsync, resendNewAccountCaptchaAsync, sendWelcomeNotificationAsync } from '../register.service';
import registerData from '../register-data';

class VerifyAccount extends Component {
    state = {
        number1: '',
        number2: '',
        number3: '',
        number4: '',
        number5: '',
        number6: '',
        showErrors: false,
        canContinue: false,
        stepActive: 4,
        stepActiveText: <>Verify <font style={{ fontWeight: "bold" }}>your email.</font></>,
        verifyLoading: false,
        resendCodeLoading: false
    }
    componentDidMount() {
        window.addEventListener('keydown', (e) => {
            if (e.keyCode === 86 && e.ctrlKey) {
                navigator.clipboard.readText().then(text => {
                    const textVal = text.replace(/\s*/g, "");
                    if (textVal.length === 6) {
                        const arr = textVal.split('');
                        arr.forEach((item, i) => {
                            this.setState({ [`number${i + 1}`]: item })
                        });
                        this.VerifyCode()
                    }
                })
            }
        });
    }
    onNext = () => {
        sendWelcomeNotificationAsync(this.props.email ? this.props.email : registerData.email, { catchError: true });
        this.props.onStepChange(6);

    }
    resendCode = async () => {
        let clientReturnUri = localStorage.getItem('clientReturnUrl') !== 'undefined' ? localStorage.getItem('clientReturnUrl') : '';
        this.setState({ resendCodeLoading: true })
        const res = await resendNewAccountCaptchaAsync(this.props.email ? this.props.email : registerData.email, '', encodeURIComponent(clientReturnUri), { catchError: true });

        if (res && res.status === 200) {
            this.setState({ resendCodeLoading: false })
        }
    }
    onChange = e => {
        let { name, value } = e.target
        if (value.length > 1) {
            return;
        }
        value = value.replace(/[^0-9]/g, '')
        this.setState({
            [name]: value,
            canContinue: false,
            showErrors: false
        }, () => {
            let num = parseInt(name.substr(name.length - 1, 1)) + 1
            let Input = document.getElementsByName(`number${num}`)[0]
            value && Input && Input.focus()

            this.VerifyCode()
        })

    }
    onKeyDown = e => {
        let { value, name } = e.target
        if (e.keyCode === 8 && !value) {
            let num = parseInt(name.substr(name.length - 1, 1)) - 1
            let Input = document.getElementsByName(`number${num}`)[0]
            Input && Input.focus()
        } else {
            return;
        }
    }
    // verify code
    VerifyCode = async () => {
        let { number1, number2, number3, number4, number5, number6 } = this.state
        if (number1 && number2 && number3 && number4 && number5 && number6) {
            this.setState({ verifyLoading: true })
            let data = {
                target: this.props.email ? this.props.email : registerData.email,
                code: `${number1}${number2}${number3}${number4}${number5}${number6}`
            };
            try {
                await consumeCaptchaAsync(data, { catchError: true })
                await cleanActionLinksAsync(this.props.email ? this.props.email : registerData.email)
                this.setState({ canContinue: true, showErrors: false });
            } catch (error) {
                this.setState({ showErrors: true });
            }
            this.setState({ verifyLoading: false })
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className={styles.stepWrap}>
                    <Step stepTitle={'Verify your Account'} stepWidth={320} stepNum={4} stepActive={this.state.stepActive} stepActiveText={this.state.stepActiveText} />
                </div>
                {this.state.stepActive === 4 ? <div className={styles.row}>
                    <p>You should receive an email with a verification code to finish creating your account shortly.</p>
                    <p>Please enter the code here, or follow the link you receive in your email.</p>
                    <p style={{ marginBottom: '10px' }}>Verification Code</p>
                    <div className={`${styles['verify-input-wrap']}`}>

                        <span className={styles.verifyBefore} > <Input name='number1' autoFocus='autoFocus' value={this.state.number1} onChange={this.onChange} /></span>
                        <span className={styles.verifyBefore} > <Input name='number2' value={this.state.number2} onChange={this.onChange} onKeyDown={this.onKeyDown} /></span>
                        <span className={styles.verifyBefore} > <Input name='number3' value={this.state.number3} onChange={this.onChange} onKeyDown={this.onKeyDown} /></span>
                        <span className={styles.verifyBefore} > <Input name='number4' value={this.state.number4} onChange={this.onChange} onKeyDown={this.onKeyDown} /></span>
                        <span className={styles.verifyBefore} > <Input name='number5' value={this.state.number5} onChange={this.onChange} onKeyDown={this.onKeyDown} /></span>
                        <Input name='number6' value={this.state.number6} onChange={this.onChange} onKeyDown={this.onKeyDown} />
                    </div>
                    {this.state.showErrors && <Alert className={`${styles['alert-margin']}`} type='warning' message='This code is incorrect. Please try again.'></Alert>}

                    <div className={styles.buttonGroup} >
                        <span onClick={this.resendCode}> {this.state.resendCodeLoading && <Spinner style={{ marginRight: '10px' }} size="sm" color="#fff" />}Resend Code</span>
                        <Button onClick={this.onNext} color="primary" size='sm' disabled={!this.state.canContinue}>{this.state.verifyLoading && <Spinner style={{ marginRight: '10px' }} size="sm" color="#fff" />}Continue</Button>
                    </div>
                </div> : <div className={styles.row}>
                        <p>Thank you for completing your information. We will attempt to match you to the correct<br />
                            organization and send your request for approval by your organization's administrator.
                        </p>
                        <p>You'll get an email soon letting you know if your account is approved or if you must contact Hexagon for further support.</p>
                        <a href='https://www.hexagonsfx.com' target="_blank" rel="noopener noreferrer" >Learn More About SFx</a>
                    </div>}
            </React.Fragment>
        )
    }
}

export default withRouter(VerifyAccount);