import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Header, SolutionBar, UserDropdownMenu, Button } from '@sfx/react-ui';
import withLocalization from '../hoc/with-localization.component';

import userManager from '../../utils/userManage'
import { UserContext } from '../userProvider'

class HeaderComponent extends Component {

    state = {
        openSolutionBar: false,
    }

    triggerSolutionBar = () => {
        this.setState({ openSolutionBar: !this.state.openSolutionBar })
    };

    render() {
        const returnURL = localStorage.getItem('IDS_RETURNURL')
        const pathname = this.props.location.pathname
        const showSign = (pathname.indexOf('Register') > 0) && (pathname !== '/Register/start')
        const { familyName, givenName, avatarUrl, user = {}, solutions } = this.context
        const { profile } = user
        const isLogin = this.props.showUserInfo

        const dropDownMenuOptions = {
            avatarUrl,
            givenName,
            familyName,
            showCamera: true,
            onSignOut: () => {
                userManager.signOut()
            },
            onCamera: () => { },
            preferenceItem: {
                name: "My preferences",
                onClick: () => {
                    this.props.history.push('/userPreferences')
                }
            },
        };

        const footerMenu = {
            accountSetting: {
                label: 'Account Settings',
                onClick: () => {
                    this.props.history.push('/accountSettings')
                    this.triggerSolutionBar()
                }
            }
        }
        const solutionsList = solutions.map(r => {
            let slnIcon = r.images && r.images[0];
            let defaultIconUrl = r.defaultImageUrl;
            let solutionIconUrl = (slnIcon && slnIcon.url) ? slnIcon.url : defaultIconUrl;

            return {
                url: r.clientUri ? r.clientUri : '/',
                id: r.id,
                name: r.name,
                shortName: r.shortName,
                img: solutionIconUrl
            };
        });
        const solutionBarOptions = {
            open: this.state.openSolutionBar,
            solutions: solutionsList,
            language: "en",
            onClose: this.triggerSolutionBar,
            footerMenu
        };

        const solutionBar = (
            this.state.openSolutionBar && <SolutionBar {...solutionBarOptions} />
        );

        const rightContent = isLogin && <UserDropdownMenu {...dropDownMenuOptions} />;


        return (
            <>
                <Header
                    loggedIn={isLogin}
                    orgName={profile && profile.OrgName}
                    title={"HxGN SFx"}
                    isShowSolutionBar={isLogin}
                    onSolutionMenuButton={() => {
                        this.triggerSolutionBar();
                    }}
                    solutionBar={solutionBar}
                    rightContent={rightContent}
                />
                {showSign && <div className="account-right-info">
                    <>
                        <span className='account-header-right-text'>Already have an account?</span>
                        <Link to={{
                            pathname: '/Account/Login',
                            search: returnURL
                        }}><Button className="account-header-right-btn" size="sm">Sign in</Button></Link>
                    </>
                </div>}
            </>

        )

    }

}

HeaderComponent.contextType = UserContext
export default withLocalization(withRouter(HeaderComponent));