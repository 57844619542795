import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import CreateAccountBasic from './registry-create-user-account.component';
import CompleteAccount from './registry-complete-account.component'
import CreateAccountOrganization from './registry-create-orgnization.component';
import CreateAccountInfo from './registry-information.component';
import VerifyAccount from './registry-verify-account.component'
import InviteSuccess from './registry-invite-success.component'
import MatchEmailError from './registry-match-email-error.component'
import SendNotificationSuccess from './registry-sendnotification-success.component'

import { defaultClientId } from '../../../config';

const stepComponentEnum = {
    1: CreateAccountBasic,
    2: CreateAccountOrganization,
    3: CreateAccountInfo,
    4: CompleteAccount,
    5: VerifyAccount,
    6: InviteSuccess,
    7: MatchEmailError,
    8: SendNotificationSuccess

};

class CreateAccount extends Component {
    state = {
        showComponent: stepComponentEnum[1],
        passedInEmail: '',
        message: '',
        persistedState: {}
    }

    componentDidMount = () => {
        let clientId = localStorage.getItem('clientId');
        if (!clientId) {
            defaultClientId.then(result => {
                localStorage.setItem('clientId', result);
            });
        }

        if (this.props.initialStep && this.props.initialStep > 0) {
            this.setState({
                showComponent: stepComponentEnum[this.props.initialStep]
            });
        }

        if (this.props.email) {
            this.setState({
                passedInEmail: this.props.email
            });
        }
    };

    onStepChange = (step, message = '', persistedState = {}) => {
        this.setState({ showComponent: stepComponentEnum[step], message: message, persistedState: persistedState });
       
    }


    render() {
        return (
            <React.Fragment>
                <this.state.showComponent onStepChange={this.onStepChange} email={this.state.passedInEmail} message={this.state.message} persistedState={this.state.persistedState}/>
            </React.Fragment>
        )
    }
}

export default withRouter(CreateAccount);
