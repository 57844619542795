import React from 'react'
import { confirmEmailAsync } from './register.service'

export default class ConfirmEmail extends React.Component {

    componentDidMount() {
        this.confirmEmail()
    }

    async confirmEmail() {
        let Userid = this.getUrlParam('userid');
        let Token = this.getUrlParam('token');
        let result = await confirmEmailAsync({ Userid, Token });
        if (result.status === 200) {
            alert('activate account success')
        }
    }

    getUrlParam(name) {
        var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
        var r = window.location.search.substr(1).match(reg);
        if (r != null) return unescape(r[2]); return null;
    }

    render() {
        return null
    }
}