import React, { Component } from 'react';
import { Route, withRouter, matchPath, Redirect, Switch } from 'react-router-dom';
import { UserProfile, Spin } from '@sfx/react-ui'
import Header from '../header'
import withLocalization from '../hoc/with-localization.component';
import UserProvider from '../userProvider'
import routes from '../../router/router'
import Action from '../action';
import userManager from '../../utils/userManage'
import { detectIE } from '../../utils/util';
import { getLocalizedStrings, langDict } from '../../utils/translations';
import Footer from '../footer';
import UnsupportedBrowserBanner from './NonSupportBrowser';
import { getUserProfileAsync, getSolutionsAsync } from '../../services/api/account.service'
import { ErrorCase } from '../../utils/util';
import Error from '../error';

const bannerPaths = ['/Register', '/Account/Login', '/ResetPassword'];
const isIE = detectIE();
const authPath = '/Account/Login'
let hasSolution = false
class Layout extends Component {
    constructor(props) {
        super(props);
        this.updateUser = (user) => {
            if (user.profile) {
                const { given_name: givenName, family_name: familyName, Avatar: avatarUrl, name } = user.profile
                this.setState({ givenName, familyName, avatarUrl, user, name })
            }
        }
        this.state = {
            user: {},
            givenName: '',
            familyName: '',
            avatarUrl: '',
            name: 'Admin',
            updateUser: this.updateUser,
            checkUserLoading: false,
            solutions: [],
            solutionLoading: true
        }

        const { pathname, search } = props.location
        // when user change password or first login , the 'IDS_Auth' flag should be cleaned up.
        if (pathname === authPath && search) {
            localStorage.removeItem('IDS_Auth')
        }

    }
    componentDidMount() {
        this.checkUser()
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.givenName !== this.state.givenName) {
            if (this.state.user.profile && this.state.user.profile.TotalSignIn === 1 && !localStorage.getItem('firstLogin')) {
                this.showUserProfile()
            }
        }
        // when user logged and direct access to Login
        const { pathname: currentPathName } = this.props.location
        const { pathname: prevPathName } = prevProps.location
        if (localStorage.getItem('IDS_Auth') && prevPathName === authPath && currentPathName === '/home') {
            this.checkUser()
        }
        if (prevPathName === '/callback' && !hasSolution) {
            const { user } = this.state
            this.getSolutionList(user)
            hasSolution = true
        }

    }
    getRequiresAuth = () => {
        const pathname = window.location.pathname
        const currentRoute = routes.find(i => {
            const routerRath = i.path.split(':')[0]
            return pathname.includes(routerRath) || `${pathname}/`.includes(routerRath)
        })
        const requiresAuth = currentRoute && currentRoute.requiresAuth
        return requiresAuth
    }
    checkUser = async () => {
        const RequiresAuth = this.getRequiresAuth()
        if (!RequiresAuth) {
            return
        }
        try {
            this.setState({ checkUserLoading: true })
            let user = await userManager.getUser()
            let sessionExist = await userManager.userMgr.querySessionStatus()
            if (user) {
                this.updateUser(user)
                this.getUserProfile(user)
                this.getSolutionList(user)
                this.setState({ checkUserLoading: false })
            } else if (sessionExist && !user) {
                userManager.signIn()
            } else {
                this.setState({ checkUserLoading: false })
                localStorage.clear()
                this.props.history.push('/Account/Login')
            }
        } catch (error) {
            this.setState({ checkUserLoading: false })
            localStorage.clear()
            this.props.history.push('/Account/Login')
        }


    }
    getUserProfile = async (user) => {
        try {
            const { profile } = user
            const { data } = await getUserProfileAsync(profile.key)
            const { avatar, givenName, surname } = data

            let avatarUrl = avatar && avatar.url
            this.setState({ givenName, familyName: surname, avatarUrl, role: profile.role, orgName: profile.OrgName, name: profile.name })
        } catch (error) {
            console.log(error)
        }
    }
    updateUserProfile = (profileObj) => {
        const { profile = {} } = this.state.user
        this.updateUser({ profile: { ...profile, ...profileObj } })
    }
    showUserProfile = () => {
        const { user } = this.state
        const { profile = {} } = user
        const supportedLanguagesList = getLocalizedStrings()
        const supportedLanguages = Object.keys(supportedLanguagesList).map(l => ({ label: langDict[l], value: l }))
        const currentLanguageValue = profile.Lang || 'en'
        const currentLanguage = supportedLanguages.find((item) => item.value === currentLanguageValue);
        const options = {
            getToken: () => { return user && user.access_token },
            initialData: {
                staticSource: {
                    supportedLanguages,
                    currentLanguage
                },
            },
            setLanguage: item => item && this.props.setLanguage(item.value),
            saveSuccessCallback: (data) => {
                const { givenName: given_name, surname: family_name } = data
                this.updateUserProfile({ given_name, family_name, name: `${data.givenName} ${data.surname}`.trim() });
            },
            uploadAvatarSuccess: (imgUrl) => {
                this.updateUserProfile({
                    Avatar: imgUrl
                });
            },
            deleteAvatarSuccess: () => {
                this.updateUserProfile({
                    Avatar: ''
                });
            },
        };
        UserProfile.show(options, () => {
            localStorage.setItem('firstLogin', false)
        })
    }
    getSolutionList = async (user) => {
        if (localStorage.getItem('IDS_solutions') && JSON.parse(localStorage.getItem('IDS_solutions')).length) {
            const solutions = JSON.parse(localStorage.getItem('IDS_solutions'))
            this.setState({ solutions }, () => {
                this.setState({ solutionLoading: false })
            })
            return
        }
        try {
            const { profile: { key: userId } } = user
            let { data: solutions } = await getSolutionsAsync(userId)
            this.setState({ solutions }, () => {
                this.setState({ solutionLoading: false })
            })
        } catch (error) {
            this.setState({ solutions: [], solutionLoading: false })
        }
    }


    render() {
        let pathname = this.props.location.pathname
        const isLogin = localStorage.getItem('IDS_Auth')

        const showBanner = isIE && bannerPaths.some(path => matchPath(pathname, path));

        return (
            <React.Fragment>
                <UserProvider updateUser={this.updateUser} userState={this.state}>
                    {showBanner && <UnsupportedBrowserBanner />}
                    <Header showUserInfo={this.getRequiresAuth()} />
                    <div className={"layout-content"}>
                        {this.state.checkUserLoading && <div className="loading-mask"><Spin size='0.2' /></div>}
                        <Switch>
                            {routes.map((route, index) => (
                                // Render more <Route>s with the same paths as
                                // above, but different components this time.
                                <Route
                                    key={index}
                                    path={route.path}
                                    exact={route.exact}
                                    render={(props) => {
                                        if (!route.requiresAuth || isLogin) {
                                            if (route.path === authPath && isLogin) {
                                                return <Redirect to={{ pathname: '/home' }} />
                                            }
                                            return <route.component {...props} route={route} />
                                        } else {
                                            return <Redirect to={{ pathname: authPath, currentLocation: props.location.pathname }} />
                                        }
                                    }}
                                />
                            ))}
                            <Route path="/action" component={Action} />
                            <Redirect exact={true} path="/" to={{ pathname: '/home' }} />
                            {/* <Route component={NoMatch} /> */}
                            <Route children={<Error case={ErrorCase.NOT_FOUND} />} />
                        </Switch>
                    </div>
                    <div className="footer">
                        <Footer />
                    </div>
                </UserProvider>
            </React.Fragment >
        )
    }
}

export default withRouter(withLocalization(Layout));