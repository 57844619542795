import React, { Component } from 'react'
import styles from './index.module.scss'
import queryStringHandler from 'query-string';

export default class ResetPasswordSuccess extends Component {
    render() {
        const name = queryStringHandler.parse(this.props.location.search).name;
        const clientReturnUrl = queryStringHandler.parse(this.props.location.search).clientReturnUrl;
        if (name === 'resetPassword') {
            return (
                <React.Fragment>
                    <div className={styles.header}>Reset password successfully</div>
                    <div className={styles.guide}>Dear user, you have reset your password successfully.</div>
                    <div className={styles.buttonGroup}>
                        <a href={(clientReturnUrl && clientReturnUrl !== null && clientReturnUrl !== "undefined") ? clientReturnUrl : '/Account/Login'} className={`${styles.linkStyle} ${styles.right}`}>Return to Sign in</a>
                    </div>
                </React.Fragment>
            )
        }

        if (name === 'activeAccount') {
            return (
                <React.Fragment>
                    <div className={styles.header}>Activated account successfully</div>
                    <div className={styles.guide}>Dear user, you have activated your account successfully.</div>
                    <div className={styles.buttonGroup}>
                        <a href={(clientReturnUrl && clientReturnUrl !== null && clientReturnUrl !== "undefined") ? clientReturnUrl : '/Account/Login'} className={`${styles.linkStyle} ${styles.right}`}>Return to Sign in</a>
                    </div>
                </React.Fragment>
            )
        }

        if (name === 'initializePassword') {
            return (
                <React.Fragment>
                    <div className={styles.header}>Initialized password successfully</div>
                    <div className={styles.guide}>Dear user, you have initialized your password successfully.</div>
                    <div className={styles.buttonGroup}>
                        <a href={(clientReturnUrl && clientReturnUrl !== null && clientReturnUrl !== "undefined") ? clientReturnUrl : '/Account/Login'} className={`${styles.linkStyle} ${styles.right}`}>Return to Sign in</a>
                    </div>
                </React.Fragment>
            )
        }
        else {
            return null
        }

    }
}