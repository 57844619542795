import React, { Component } from 'react';
import styles from './step.module.scss';

class StepItem extends Component {

    render() {
        const { active, showLine, lineWidth } = this.props;
        return (
            <div className={styles.stepItemWrap}>
                <div className={styles.stepItem} >
                    {active && <span className={styles.active}></span>}
                </div>
                {<div className={styles.line} style={{ width: `${showLine ? lineWidth : 0}px` }}></div>}
                {this.props.children && <div className={styles.stepText}>{this.props.children}</div>}
            </div >
        )
    }
}

export default StepItem;