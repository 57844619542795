import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import queryStringHandler from 'query-string';
import { Button, FormGroup, Spinner, Form, Label } from 'reactstrap';

import withLocalization from '../hoc/with-localization.component';
import { validatePassword } from '../../utils/formValidation'
import { RetrieveOperationAsync, ResetPasswordAsync, ActivateAccountAsync } from './action.service';
import Alert from '../commonComponents/alert'
import styles from './action.module.scss'
import PasswordMask from '../commonComponents/password';
import Register from '../register';
import { resendNewAccountCaptchaAsync } from '../register/register.service';

class Action extends Component {
    constructor() {
        super();
        this.state = {
            token: '',
            operationInstance: '',
            password: '',
            passwordValid: false,
            passwordValidMsg: '',
            confirmPassword: '',
            confirmPasswordValid: false,
            confirmPasswordValidMsg: '',

            isSubmit: false,
            isShowSubmitLoading: false,
            isActivateButtonDisabled: false,

            isSubmitError: false,

            sentCaptchaSuccessfully: false,
            clientRediretUri: ''
        }
    }

    componentDidMount = () => {
        let token = '';

        if (queryStringHandler.parse(this.props.location.search).token || queryStringHandler.parse(this.props.location.search).Token) {
            if (queryStringHandler.parse(this.props.location.search).token) {
                token = queryStringHandler.parse(this.props.location.search).token;
            } else {
                token = queryStringHandler.parse(this.props.location.search).Token;
            }
        }

        if (token) {
            let encodedToken = encodeURIComponent(token);
            RetrieveOperationAsync(encodedToken, { catchError: true })
                .then(res => {
                    this.setState({
                        operationInstance: res.data,
                        token: encodedToken
                    });
                })
                .catch(error => {
                    if (error.response && error.response.status === 404) {
                        this.setState({
                            clientRediretUri: error.response.data
                        });
                    }

                    this.setState({
                        operationInstance: '',
                        token: encodedToken
                    })
                    console.dir(error);
                });
        }
    };


    resetPasswordAsync = e => {
        e.preventDefault();

        this.setState({
            isSubmit: true,
            isShowSubmitLoading: true
        })

        let puModel = {
            token: this.state.token,
            newPassword: this.state.password,
            confirmedNewPassword: this.state.confirmPassword,
        };
        let clientReturnUrl = this.state.operationInstance.redirectUri ? encodeURIComponent(this.state.operationInstance.redirectUri) : '';

        ResetPasswordAsync(puModel, { catchError: true })
            .then(res => {
                this.setState({ isShowSubmitLoading: false, isSubmitError: false })
                if (this.state.operationInstance.operation === 'PasswordReset') {
                    this.props.history.push(`/ResetPassword/success?name=resetPassword&clientReturnUrl=${clientReturnUrl}`);
                } else {
                    this.props.history.push(`/ResetPassword/success?name=initializePassword&clientReturnUrl=${clientReturnUrl}`);
                }
            })
            .catch(error => {
                this.setState({ isSubmitError: true, isSubmit: false, password: '', confirmPassword: '', isShowSubmitLoading: false });
            });
    };

    formValidate = (name, value) => {
        let { valid, message } = validatePassword(value);

        if (name === 'password' && valid) {
            if (value !== this.state.confirmPassword) {
                valid = false;
                if (this.state.confirmPassword) {
                    message = 'notMatch';
                } else {
                    message = '';
                    this.setState({ confirmPasswordValidMsg: '' });
                }
            } else {
                this.setState({ confirmPasswordValid: true })
            }
        }

        if (name === 'confirmPassword' && valid) {
            if (value !== this.state.password) {
                valid = false;
                if (this.state.password) {
                    message = 'notMatch';
                } else {
                    message = '';
                    this.setState({ passwordValidMsg: '' });
                }
            } else {
                this.setState({ passwordValid: true });
            }

        }

        this.setState({
            [`${name}Valid`]: valid,
            [`${name}ValidMsg`]: message,
        })
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;



        this.setState({
            [name]: value,
            isSubmitError: false
        }, () => {
            this.formValidate(name, value);
        });
    }

    activateAccount = e => {
        e.preventDefault();

        this.setState({ isShowSubmitLoading: true })

        let aaModel = {
            token: this.state.token,
            userId: this.state.operationInstance.userId,
            userName: this.state.operationInstance.userName
        };
        let clientReturnUrl = this.state.operationInstance.redirectUri ? encodeURIComponent(this.state.operationInstance.redirectUri) : '';

        ActivateAccountAsync(aaModel, { catchError: true })
            .then(res => {
                this.setState({ isShowSubmitLoading: false, isActivateButtonDisabled: true })
                this.props.history.push(`/ResetPassword/success?name=activeAccount&clientReturnUrl=${clientReturnUrl}`);
            })
            .catch(error => {
                this.setState({ isShowSubmitLoading: false, isActivateButtonDisabled: true })
            });
    };

    sendCode = async e => {
        e.preventDefault();

        this.setState({ isShowSubmitLoading: true });
        let clientRedirectUri = this.state.operationInstance.redirectUri;
        if (clientRedirectUri === undefined || clientRedirectUri === 'null') {
            clientRedirectUri = '';
        }
        const res = await resendNewAccountCaptchaAsync(this.state.operationInstance.userName, this.state.operationInstance.id, clientRedirectUri, { catchError: true });

        if (res && res.status === 200) {
            this.setState({ isShowSubmitLoading: false, sentCaptchaSuccessfully: true });
        } else {
            this.setState({ isShowSubmitLoading: false, isSubmitError: true });
        }
    };

    getErrorMessage() {
        let errorMessage;
        if (!this.state.isSubmitError) {
            if ((!this.state.passwordValid && this.state.passwordValidMsg) || (!this.state.confirmPasswordValid && this.state.confirmPasswordValidMsg)) {
                if (this.state.confirmPasswordValidMsg === 'notMatch' || this.state.passwordValidMsg === 'notMatch') {
                    errorMessage = (<div className={styles.rules}>
                        Sorry, the passwords you entered do not match. Please try again.
                    </div>);
                } else {
                    errorMessage = (<div className={styles.rules}>Password should meet requirements:
                        <ul>
                            <li>8-32 characters in length</li>
                            <li>At least one uppercase letter</li>
                            <li>At least one lowercase letter</li>
                            <li>At least one number</li>
                            <li>At least one special character</li>
                        </ul>
                    </div>);
                }
            }

        } else {
            errorMessage = (<div className={styles.rules}>
                Sorry, failed to submit your request.Please try again later or <a target="_blank" rel="noopener noreferrer" href="https://support.hexagonmi.com/s/">contact our customer service</a>.
            </div>);
        }

        return errorMessage;
    }


    render() {
        const errorMessage = this.getErrorMessage();
        const isShowErrorMsg = (((!this.state.passwordValid && this.state.password) || (!this.state.confirmPasswordValid && this.state.confirmPassword)) && errorMessage) || this.state.isSubmitError;

        const clientRediretUri = (this.state.clientRediretUri === '' || this.state.clientRediretUri === undefined || this.state.clientRediretUri === 'null') ?
            '/Account/Login' : this.state.clientRediretUri;

        if (this.state.token) {
            if (this.state.operationInstance) {
                if (this.state.operationInstance.operation === 'UserCreatedByAdmin' ||
                    this.state.operationInstance.operation === 'PasswordReset' ||
                    this.state.operationInstance.operation === 'UserCreatedByCustomerAdmin') {
                    return (
                        <React.Fragment>
                            <div className={styles.actionWrap}>
                                <div className={styles.formPanel}>
                                    <img className={styles.logo} src="/images/Hexagon_RGB_REVERSED.png" alt="LOGO" />
                                    <div className={styles.header}>{this.state.operationInstance.operation === 'PasswordReset' ? "Reset Password" : "Initialize Password"}</div>
                                    <Form onSubmit={(e) => this.resetPasswordAsync(e)}>
                                        <FormGroup>
                                            <Label for="password">New Password{!this.state.password && <span className={styles.mark}>*</span>}</Label>
                                            <PasswordMask

                                                type="password"
                                                fieldName="password"
                                                placeHolder={''}
                                                value={this.state.password}
                                                onChange={this.handleInputChange}

                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <Label for="confirmPassword">Verify Password{!this.state.confirmPassword && <span className={styles.mark}>*</span>}</Label>
                                            <PasswordMask

                                                type="password"
                                                fieldName="confirmPassword"
                                                value={this.state.confirmPassword}
                                                placeHolder={''}
                                                onChange={this.handleInputChange}

                                            />
                                        </FormGroup>

                                        <div className={styles.btnArea}>
                                            <Button
                                                disabled={!this.state.passwordValid || !this.state.confirmPasswordValid || this.state.isSubmit}
                                                type="submit"
                                                color="primary"
                                                size="sm">
                                                {
                                                    this.state.isShowSubmitLoading && <span className={styles.buttonSpinner}><Spinner size="sm" /></span>
                                                }
                                                Submit</Button>
                                        </div>
                                    </Form>
                                    {
                                        isShowErrorMsg && <Alert type='warning' message={errorMessage}></Alert>
                                    }

                                </div>
                            </div>
                        </React.Fragment>
                    );
                }

                if (this.state.operationInstance.operation === 'UserSelfRegistration') {
                    return (
                        <React.Fragment>
                            <div className={styles.actionWrap}>
                                <div className={styles.formPanel}>
                                    <img className={styles.logo} src="/images/Hexagon_RGB_REVERSED.png" alt="LOGO" />
                                    <Form onSubmit={(e) => this.activateAccount(e)}>
                                        <div className={styles.actionBtnArea}>
                                            <Button
                                                type="submit"
                                                disabled={this.state.isActivateButtonDisabled}
                                                color="primary"
                                                size="sm">
                                                {
                                                    this.state.isShowSubmitLoading && <span className={styles.actionButtonSpinner}><Spinner size="sm" /></span>
                                                }
                                                Activate Account: {this.state.operationInstance.userName}</Button>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </React.Fragment>
                    );
                }

                if (this.state.operationInstance.operation === 'ApplyCatchaForNewAccount') {
                    return (<Register initialStep="5" email={this.state.operationInstance.userName} />);
                }

                if (this.state.operationInstance.operation === 'CaptchaGenerated') {
                    return (
                        <React.Fragment>
                            {!this.state.sentCaptchaSuccessfully &&
                                <div className={styles.actionWrap}>
                                    <div className={styles.formPanel}>
                                        <img className={styles.logo} src="/images/Hexagon_RGB_REVERSED.png" alt="LOGO" />
                                        <Form onSubmit={(e) => this.sendCode(e)}>
                                            <div className={styles.actionBtnArea}>
                                                <Button
                                                    type="submit"
                                                    disabled={this.state.isActivateButtonDisabled}
                                                    color="primary"
                                                    size="sm">
                                                    {
                                                        this.state.isShowSubmitLoading && <span className={styles.actionButtonSpinner}><Spinner size="sm" /></span>
                                                    }
                                                    Send code to: {this.state.operationInstance.userName}</Button>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            }
                            {
                                this.state.sentCaptchaSuccessfully &&
                                <div className={styles.actionWrap}>
                                    <div className={styles.formPanel}>
                                        <img className={styles.logo} src="/images/Hexagon_RGB_REVERSED.png" alt="LOGO" />

                                        <div className={styles.header}>Thanks</div>
                                        <div className={styles.guide}>New CAPTCHA has been sent out successfully.</div>
                                    </div>
                                </div>
                            }
                        </React.Fragment>
                    );
                }

            } else {
                return (
                    <div className={styles.actionWrap}>
                        <div className={styles.formPanel}>
                            <img className={styles.logo} src="/images/Hexagon_RGB_REVERSED.png" alt="LOGO" />
                            <div className={styles.header}>Oops</div>
                            <div className={styles.guide}>Your link is not available any more!</div>
                            <a href={clientRediretUri}>Return to Sign In</a>
                        </div>
                    </div>
                    // <React.Fragment>
                    //     Invalid action request
                    // </React.Fragment>
                );
            }
        } else {
            return (
                <React.Fragment>
                    <div className={styles.loadWrap}>
                        <Spinner />
                    </div>
                </React.Fragment>
            );
        }
    }

}

export default withRouter(withLocalization(Action))

