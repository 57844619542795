import React, { Component } from 'react';
import { withRouter, Route } from 'react-router-dom';
import cx from 'classnames';
import styles from './registry.module.scss';
import withLocalization from '../hoc/with-localization.component';
import RegistryStart from './registry-start.component';
import CreateAccount from './registry-create-account';

class Register extends Component {
  onGetStarted = () => {
    this.props.history.push(`${this.props.match.url}/createAccount`);
  };

  render() {
    const match = this.props.match;
    const isStart = this.props.location.pathname === '/Register/start';
    return (
      <div className={`${styles['page-container']}`}>
        <div className={styles.CarouselWrap}>
          <div className={styles.CarouselWrapMask}></div>
        </div>
        <div className={styles.rightContentBac}></div>
        <div className={styles.rightContent}>
          <div className={cx(styles.registryWrap, isStart && styles.startComp)}>
            <div className="text-center">
              <img
                className={styles.logo}
                src="/images/Hexagon_RGB_REVERSED.png"
                alt=""
              ></img>
            </div>

            {this.props.initialStep && this.props.initialStep > 0 ? (
              <CreateAccount
                initialStep={this.props.initialStep}
                email={this.props.email}
              />
            ) : (
              <>
                <Route
                  exact
                  path={`${match.url}/start`}
                  render={() => {
                    return <RegistryStart onGetStarted={this.onGetStarted} />;
                  }}
                />
                <Route
                  exact
                  path={`${match.url}/createAccount`}
                  component={CreateAccount}
                />
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(withLocalization(Register));
