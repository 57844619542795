import LocalizedStrings from 'react-localization';
import { en } from './lang/en';
import { es } from './lang/es';
import { de } from './lang/de';
import { it } from './lang/it';
import { ja } from './lang/ja';
// import { xx } from './lang/xx';
import { zh } from './lang/zh-chs';
import { zht } from './lang/zh-cht';

export const langDict = {
    en: "English",
    es: "Español",
    de: "Deutsch",
    it: "Italiano",
    ja: "日本語",
    zh: "中文简体",
    zht: "中文繁體",
    xx: "Test"
}

export const getLocalizedStrings = () => {
    let stringsObj = {
        en: en,
        es: es,
        de: de,
        it: it,
        ja: ja,
        zh: zh,
        zht: zht
    }

    //const prod = (process.env.REACT_APP_PROD && process.env.REACT_APP_PROD === 'true') ? true : false;
    //if(!prod) {
    //    stringsObj = { ...stringsObj, xx: xx };
    //} 

    return stringsObj;
}

export const translations = new LocalizedStrings(getLocalizedStrings());