import React, { Component } from 'react'
import { Row, Col, Container } from 'reactstrap';
import { withRouter, Link } from 'react-router-dom';
import queryStringHandler from 'query-string';

import { Spin } from '@sfx/react-ui'

import withLocalization from '../hoc/with-localization.component';
import { readErrorInformation } from "./error.service";
import { ErrorCase } from '../../utils/util';

class Error extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loadingErrorInfo: false,
            errorInfo: null,
            errorCase: this.props.case
        };
    }

    componentDidMount = () => {
        let errorId = queryStringHandler.parse(this.props.location.search).errorId;

        if (window.top.location.pathname !== '/error') {
            window.top.location.href = `${window.top.location.origin}/error?errorId=${errorId}`;
        }

        if (errorId) {
            this.setState({
                loadingErrorInfo: true
            });

            readErrorInformation(errorId, { catchError: true }).then(res => {
                if (res.status === 200) {
                    this.setState({
                        errorInfo: res.data
                    });
                }

                this.setState({
                    loadingErrorInfo: false
                });
            }).catch(err => {
                this.setState({
                    loadingErrorInfo: false
                });
            });
        }
    };

    render() {
        return (
            <React.Fragment>
                <Container className="tip-content-wrap">
                    <Row>
                        <Col xs="10">
                            <h1>
                                Oops
                            </h1>
                        </Col>
                    </Row>
                    <hr />
                    {
                        !this.state.loadingErrorInfo && this.state.errorCase === ErrorCase.NOT_FOUND &&
                        <Row>
                            <Col xs="10">
                                <h3>
                                    Not Found
                                </h3>
                            </Col>
                        </Row>
                    }

                    {this.state.loadingErrorInfo && <Spin size='0.2' />}

                    {
                        !this.state.loadingErrorInfo && this.state.errorInfo &&
                        <>
                            <h3>Error Details</h3>
                            <br />
                            <Row>
                                <Col xs="3">
                                    <h5>
                                        Client Id:
                                    </h5>
                                </Col>
                                <Col xs="7">
                                    {this.state.errorInfo.clientId}
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="3">
                                    <h5>
                                        Error:
                                    </h5>
                                </Col>
                                <Col xs="7">
                                    {this.state.errorInfo.error}
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="3">
                                    <h5>
                                        Error Description:
                                    </h5>
                                </Col>
                                <Col xs="7">
                                    {this.state.errorInfo.errorDescription}
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="3">
                                    <h5>
                                        RequestId:
                                    </h5>
                                </Col>
                                <Col xs="7">
                                    {this.state.errorInfo.requestId}
                                </Col>
                            </Row>
                        </>
                    }

                    {
                        !this.state.loadingErrorInfo && !this.state.errorInfo && !this.state.errorCase &&
                        <Row>
                            <Col xs="10">
                                <h3>
                                    Unknown problem
                                </h3>
                            </Col>
                        </Row>
                    }

                    {
                        !this.state.loadingErrorInfo &&
                        <Row>
                            <Col xs="10">
                                <br />
                                <Link to="/">Go back to the home page</Link>
                            </Col>
                        </Row>
                    }

                </Container>
            </React.Fragment >
        );
    }
}

export default withRouter(withLocalization(Error));