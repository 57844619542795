import React, { Component } from 'react';
import { Spin } from '@sfx/react-ui'
import { UserConsumer } from '../userProvider'

import { SigninCallbackComponent, SilentRedirectCallbackComponent } from '@sfx/authentication';
import userManager from '../../utils/userManage';


export class SigninCallback extends Component {

    successCallback = (user, updateUser) => {
        if (user) {
            updateUser(user)
        }
        let currentIDSRedirectURL = localStorage.getItem('currentIDSRedirectURL');
        if (currentIDSRedirectURL) {
            localStorage.removeItem('currentIDSRedirectURL');
            this.props.history.push(currentIDSRedirectURL)
        } else {
            this.props.history.push("/home")
        }

    };

    render() {
        return (
            <UserConsumer >
                {({ updateUser }) => (
                    <SigninCallbackComponent
                        userManager={userManager.userMgr}
                        signinType={'redirect'}
                        successCallback={(user) => this.successCallback(user, updateUser)} >
                        <Spin size='0.2' />
                    </SigninCallbackComponent>)}
            </UserConsumer>
        )
    }
}


export class SilentRedirectCallback extends Component {

    successCallback = (user, updateUser) => {
        if (user) {
            updateUser(user)
        }
        this.props.history.push("/home")
    };

    render() {
        return (
            <UserConsumer >
                {({ updateUser }) => (
                    <SilentRedirectCallbackComponent
                        userManager={userManager.userMgr}
                        successCallback={(user) => this.successCallback(user, updateUser)} >
                        <Spin size='0.2' />
                    </SilentRedirectCallbackComponent>)}
            </UserConsumer>
        )
    }
}

