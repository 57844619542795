import { translations } from './translations';
import moment from 'moment';


export const validatePassword = (password) => {
  var minMaxLength = /^[\s\S]{8,32}$/,
    upper = /[A-Z]/,
    lower = /[a-z]/,
    number = /[0-9]/,
    special = /[ !"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]/;

  let valid = true;
  let message = '';
  if (minMaxLength.test(password) &&
    upper.test(password) &&
    lower.test(password) &&
    number.test(password) &&
    special.test(password)
  ) {
    return { valid, message };
  }
  else {
    valid = false;
    if (!minMaxLength.test(password)) {
      message = 'The password should be between 8 and 32 characters in length'
    }
    else if (!upper.test(password)) {
      message = 'At least one uppper case letter should be included'
    }
    else if (!lower.test(password)) {
      message = 'At least one lower case letter should be included'
    }
    else if (!number.test(password)) {
      message = 'At least one number should be included'
    }
    else if (!special.test(password)) {
      message = 'At least one special letter should be included'
    }
    return { valid, message }
  }
}

export const validateEmail = (email) => {
  let re = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/
  let valid = re.test(String(email).toLowerCase());
  let message = valid ? '' : 'Please input valid email!';
  return { valid, message };
}

export const validateStringValue = (val, required = false, min = 0, max = 0) => {
  require('./translations');
  let isValid = true;
  let errorMessage = '';

  val = val.trim();
  if (required && val.length === 0) {
    isValid = false;
    errorMessage = translations.errorRequired;
    return { valid: isValid, errorMessage: errorMessage };
  }

  if (min > 0 && val.length < min) {
    isValid = false;
    errorMessage = translations.formatString(translations.errorMinChar, min);
    return { valid: isValid, errorMessage: errorMessage };
  }

  if (max > 0 && val.length > max) {
    isValid = false;
    errorMessage = translations.formatString(translations.errorMaxChar, max);
    return { valid: isValid, errorMessage: errorMessage };
  }

  return { valid: isValid, errorMessage: errorMessage };
}

export const validateDuplicateName = (val, currentAssetId, assets) => {
  require('./translations');
  let isDuplicate = false;
  let errorMessage = '';

  /* Find if there are duplicate asset names.
     1. Exclude the asset itseft.
     2. compare the short name under HMISystem property.
     3. If no short name, compare the ObejctID.
  */
  for (let asset of assets) {
    if (asset.ObjectID !== currentAssetId && (val === asset.HMISystem.ShortName || val === asset.ObjectID)) {
      isDuplicate = true;
      errorMessage = translations.duplicateAssetName;
      break;
    }
  }

  return { duplicate: isDuplicate, errorMessage: errorMessage };
}

export const validateDateValue = (val, required = false) => {
  let isValid = true;
  let thisDate = new Date(val);
  let errorMessage = '';

  if ((required || val) && !Date.parse(thisDate)) {
    isValid = false;
    errorMessage = this.props.translate('invalidDate');
  }

  return { isValid, errorMessage };
}

export const validateShiftName = (val, required = false, min = 0, max = 0, shiftNamesToCheck = []) => {
  //Check shiftName string and if duplicate
  require('./translations');
  let isValid = true;
  let errorMessage = '';

  val = val.trim();
  if (required && val.length === 0) {
    isValid = false;
    errorMessage = translations.errorRequired;
  }

  if (min > 0 && val.length < min) {
    isValid = false;
    errorMessage = translations.formatString(translations.errorMinChar, min);
  }

  if (max > 0 && val.length > max) {
    isValid = false;
    errorMessage = translations.formatString(translations.errorMaxChar, max);
  }

  if (shiftNamesToCheck.find(s => s.ShiftName === val)) {
    isValid = false;
    errorMessage = translations.errorDuplicateShiftName;
  }

  return { valid: isValid, errorMessage: errorMessage };
}

export const validateInteger = (val, required = false) => {
  require('./translations');
  let isValid = true;
  let errorMessage = '';

  val = val.trim();
  const re = /^([1-9]\d*|0)$/;
  isValid = re.test(String(val).toLowerCase());
  errorMessage = translations.formatString(translations.errorNotInteger, val);

  return { valid: isValid, errorMessage: errorMessage };
}

export const validateShiftStartEnd = (startVal, endVal) => {
  require('./translations');
  let isValid = true;
  let errorMessage = '';

  // Is start or end time blank?
  if (startVal !== '' && endVal === '') {
    isValid = false;
    errorMessage = translations.errorNoEndTime;
  }

  if (startVal === '' && endVal !== '') {
    isValid = false;
    errorMessage = translations.errorNoStartTime;
  }

  // Is end time after start time?
  if (startVal !== '' && endVal !== '') {
    const start = '2019-01-01 ' + startVal;
    const end = '2019-01-01 ' + endVal;
    let isEndAfterStart = moment(start) < moment(end);
    if (!isEndAfterStart) {
      isValid = false;
      errorMessage = translations.errorStartAfterEnd;
    }
  }

  return { valid: isValid, errorMessage: errorMessage };
}

export const validateBreakValue = (val, startVal, endVal) => {
  require('./translations');
  let isValid = true;
  let errorMessage = '';

  if (val && startVal && endVal) {
    val = val.trim();
    const regX = /^([1-9]\d*|0)$/;
    if (!regX.test(String(val).toLowerCase())) {
      isValid = false;
      errorMessage = translations.formatString(translations.errorNotInteger, val);
    }

    if (startVal !== '' && endVal !== '' && val !== '') {
      const start = '2019-01-01 ' + startVal;
      const end = '2019-01-01 ' + endVal;
      var shiftMinutes = moment.duration(moment(end, "YYYY-MM-DD HH:mm").diff(moment(start, "YYYY-MM-DD HH:mm"))) / 60000;
      if (val > shiftMinutes) {
        isValid = false;
        errorMessage = translations.errorLongDownTime;
      }
    }
  }

  return { valid: isValid, errorMessage: errorMessage };
}

export const validateHolidayDate = (val, required = false, holidaysToCheck = [], currentVal = '') => {
  require('./translations');
  let isValid = true;
  let errorMessage = '';

  if (required && !val) {
    isValid = false;
    errorMessage = translations.errorRequired;

    return { valid: isValid, errorMessage: errorMessage };
  }

  if (currentVal && currentVal !== moment(val).format('YYYY-MM-DD')) {
    if (holidaysToCheck.find(h => h.HolidayDate === moment(val).format('YYYY-MM-DD'))) {
      isValid = false;
      errorMessage = translations.errorHolidayNotUnique;
    }
  }

  if (!currentVal) {
    if (holidaysToCheck.find(h => h.HolidayDate === moment(val).format('YYYY-MM-DD'))) {
      isValid = false;
      errorMessage = translations.errorHolidayNotUnique;
    }
  }

  return { valid: isValid, errorMessage: errorMessage };
}

export const validateScheduleValue = (val, required = false, min = 0, max = 0, scheduleNamesToCheck = []) => {
  //Check scheduleName string and uniqueness
  require('./translations');
  let isValid = true;
  let errorMessage = '';

  val = val.trim();
  if (required && val.length === 0) {
    isValid = false;
    errorMessage = translations.errorRequired;
  }

  if (min > 0 && val.length < min) {
    isValid = false;
    errorMessage = translations.formatString(translations.errorMinChar, min);
  }

  if (max > 0 && val.length > max) {
    isValid = false;
    errorMessage = translations.formatString(translations.errorMaxChar, max);
  }

  if (scheduleNamesToCheck.find(s => s.ObjectName === val)) {
    isValid = false;
    errorMessage = translations.errorDuplicateScheduleName;
  }

  return { valid: isValid, errorMessage: errorMessage };
}

export const validateStringUniqueValue = (val, required = false, min = 0, max = 0, namesToCheck = []) => {
  require('./translations');
  let isValid = true;
  let errorMessage = '';

  val = val.trim();
  if (required && val.length === 0) {
    isValid = false;
    errorMessage = translations.errorRequired;
  }

  if (min > 0 && val.length < min) {
    isValid = false;
    errorMessage = translations.formatString(translations.errorMinChar, min);
  }

  if (max > 0 && val.length > max) {
    isValid = false;
    errorMessage = translations.formatString(translations.errorMaxChar, max);
  }

  if (namesToCheck.find(s => s.Name === val)) {
    isValid = false;
    errorMessage = translations.errorDuplicateName;
  }

  return { valid: isValid, errorMessage: errorMessage };
}



export const validateMobile = (mobile) => {
  let valid = true;
  let message = '';
  if (!(/^1[34578]\d{9}$/.test(mobile))) {
    valid = false;
    message = 'please input valid mobile';
    return { valid, message }
  }
  return { valid, message }
}

export const validateMobileOnlyNumber = (mobile) => {
  let valid = true;
  let message = '';
  if (!(/^[0-9]*$/.test(mobile))) {
    valid = false;
    message = 'please input valid mobile';
    return { valid, message }
  }
  return { valid, message }
}

export const validatePhoneNumber = (value) => {
  let valid = true;
  let message = '';
  const reg = /^(\+?( |-|\.)?\d{1,3}( |-|\.)?)?(\(\+?\d{1,5}( |-|\.)?\d{0,3}\)|\d{2,4})( |-|\.)?(\d{2,}( |-|\.)?\d+)$/;
  const regSpecial = /[!"#$%&'*,:;<=>?@[\\\]^_`{|}~]+/;
  if (regSpecial.test(value)) {
    valid = false;
    message = 'Please only use +, -, ( ), ., and spaces to divide phone numbers in this section.'
    return { valid, message, type: 'special' }
  }
  if (!reg.test(value)) {
    valid = false;
    message = 'please input valid mobile';
  }
  return { valid, message }
}