import React, { Component, Suspense } from 'react';
import './styles/index.scss';
import Layout from './components/layout'
import { Spin } from '@sfx/react-ui'

class App extends Component {
  render() {
    return (
      <Suspense fallback={<Spin size='0.2' />}>
        <Layout />
      </Suspense>
    );
  }
}

export default App;
