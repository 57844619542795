import React, { Component } from 'react';
import { IconClose } from '@sfx/icons'
import styles from './toolTip.module.scss';

class ToolTip extends Component {
    constructor(props) {
        super(props);

        this.ref = React.createRef();
    }

    state = {
        show: false
    }

    componentDidMount() {
        window.addEventListener('click', this.handleClick)
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClick);
    }

    handleClick = (e) => {
        const node = this.ref.current;
        const target = e.target;

        if (!node) {
            return;
        }

        if (!node.contains(target)
            && node !== target) {
            this.setState({ show: false });
        }

    }

    trigger = () => {
        this.setState({
            show: !this.state.show
        })
    }

    onClose = () => {
        this.setState({
            show: false
        })
    }

    render() {
        const { show } = this.state;
        const { content, placement, width, children } = this.props;


        return (
            <div className={styles[`toolTip-wrap`]} ref={this.ref}>
                {show && <div className={styles[`toolTip-box-${placement}`]} style={{ width: `${width}` }}>
                    {content}
                    <div className={styles[`arrow-${placement}`]}></div>
                    <span onClick={this.onClose} className={styles['close']}><IconClose size="16" /></span>
                </div>}
                <span onClick={this.trigger}>
                    {children}
                </span>
            </div>
        )
    }
}

ToolTip.defaultProps = {
    content: '',
    placement: 'top',
    arrowPointAtCenter: true

}

export default ToolTip;