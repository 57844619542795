import React, { Component } from 'react';
import withLocalization from '../hoc/with-localization.component';
import { Footer as ComFooter } from '@sfx/react-ui';

class Footer extends Component {
	render() {
		const links = [
			{ name: this.props.translate('about'), link: 'https://www.hexagonmi.com/en-US/products/software/sfx-solutions' },
			{ name: this.props.translate('legal'), link: 'https://www.hexagonmi.com/terms' },
			{ name: this.props.translate('privacy'), link: 'https://www.hexagonmi.com/en-US/about-us/privacy-policy' },
			{ name: this.props.translate('cookies'), link: 'https://www.hexagonmi.com/en-US/about-us/privacy-policy' },
			{ name: this.props.translate('contact'), link: 'https://www.hexagonmi.com/contact-us' },
		];
		const copyright = `${(new Date()).getFullYear()} ${this.props.translate('copyright')}`;
		const version = sessionStorage.getItem('appVersion');
		return (
			<ComFooter links={links} version={version} copyright={copyright} />
		)
	}
}

export default withLocalization(Footer);

