import apiService from '../../services/api.service'

export const validateEmailAsync = (email, opts) => {
    return apiService.get(`/api/users/check/email/${email}`, {}, opts)
}


export const validateGenericEmailAsync = (email, opts) => {
    return apiService.get(`/api/users/check/generic_email/${email}`, {}, opts)
}

export const validateOrgNameAsync = (name, opts) => {
    return apiService.get(`/api/organizations/check/${name}`, {}, opts)
}

export const getCountryPhoneCodeListAsyc = (opts) => {
    return apiService.get('/api/geo/callingcodes', {}, opts)
}

export const getCountryListAsync = (opts) => {
    return apiService.get('/api/geo/country', {}, opts)
}

export const getStateListAsync = (countryId, opts) => {
    return apiService.get(`/api/geo/${countryId}/state`, {}, opts)
}

export const getCityListAsync = (stateId, opts) => {
    return apiService.get(`/api/geo/${stateId}/city`, {}, opts)
}

export const registerAsync = (body, opts) => {
    return apiService.post('/api/user/self', body, opts)
}

export const confirmEmailAsync = (body, opts) => {
    return apiService.post('/api/users/confirmEmail', body, opts)
}

export const consumeCaptchaAsync = (body, opts) => {
    return apiService.put('/api/user/self/activatebycode', body, opts)
}

export const cleanActionLinksAsync = (userName, opts) => {
    return apiService.post(`/api/operation/${userName}`, {}, opts);
}

export const resendNewAccountCaptchaAsync = (userName, operationId, clientReturnUri, opts) => {
    return apiService.post(`/api/user/self/resendcode/${userName}/${operationId}?clientReturnUri=${clientReturnUri}`, {}, opts);
}

export const sendWelcomeNotificationAsync = (email, opts) => {
    return apiService.post(`/api/user/self/welcome/${email}`, {}, opts);
}

export const checkEmailDomainExistenceAsync = (email, opts) => {
    return apiService.get(`/api/users/check/domain/${email}`, {}, opts)
}

export const sendAccountRegistrationNotificationAsync = (requesttype, body, opts) => {
    return apiService.post(`/api/user/self/accountregistrationaction/${requesttype}`, body, opts);
}