import axios from 'axios';
import { apiVersionPlaceholder } from './globalConstant'
export let appConfig = ''
export const apiBaseUrl = axios.get(process.env.PUBLIC_URL + '/config/app.config.json')
    .then(res => res.data)
    .then(data => {
        appConfig = data
        if (!data || !data.APIHostOverride) {
            appConfig.APIHostOverride = `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;
        }
        return appConfig.APIHostOverride;
    });

export const solutionId = axios.get(process.env.PUBLIC_URL + '/config/app.config.json')
    .then(res => res.data)
    .then(data => {
        return data.SolutionID;
    });

export const defaultClientId = axios.get(process.env.PUBLIC_URL + '/config/app.config.json')
    .then(res => res.data)
    .then(data => {
        return data.DefaultClientId;
    });

export const assetManagerDownloadLink = axios.get(process.env.PUBLIC_URL + '/config/app.config.json')
    .then(res => res.data)
    .then(data => {
        return data.AssetManagerDownloadLink;
    });

export const showLegacySFxDesktopReminder = axios.get(process.env.PUBLIC_URL + '/config/app.config.json')
    .then(res => res.data)
    .then(data => {
        return data.ShowLegacySFxDesktopReminder;
    });

export const learnmoreLink = axios.get(process.env.PUBLIC_URL + '/config/app.config.json')
    .then(res => res.data)
    .then(data => {
        return data.LearnmoreLink;
    });

export const showMigrationLoginReminder = axios.get(process.env.PUBLIC_URL + '/config/app.config.json')
    .then(res => res.data)
    .then(data => {
        return data.ShowMigrationLoginReminder.toLowerCase() === 'true' && true;;
    });

export const uiVersion = GetVersion();

function GetVersion() {
    const versionList = process.env.REACT_APP_VERSION.split(".");
    if (versionList && versionList.length > 2) {
        return `${versionList[0]}.${versionList[1]}.${apiVersionPlaceholder}.${versionList[versionList.length - 1]}`;
    }
    return `1.0.${apiVersionPlaceholder}.0`;
}


export const IsEnableUserSelfRegistration = () => axios.get(process.env.PUBLIC_URL + '/config/app.config.json')
    .then(res => res.data)
    .then(data => {
        return data.EnableUserSelfRegistration === 'true' && true;
    });