import React, { Component } from 'react'
import { Container } from 'reactstrap'
import { withRouter, Route } from 'react-router-dom'
import styles from './index.module.scss'
import ResetPasswordStep1 from './resetPassword-step1.component'
import ResetPasswordStep2 from './resetPassword-step2.component'
import ResetPasswordSuccess from './resetPassword-success.component'


class ResetPassword extends Component {
    render() {
        let match = this.props.match;
        return (
            <React.Fragment>
                <div>
                    <Container className={styles.auth + " d-flex flex-column"}>
                        <div className="d-flex align-items-center justify-content-between">
                            <img className={styles.logo} src="/images/Hexagon_RGB_REVERSED.png" alt="LOGO" />
                        </div>

                        <Route exact path={`${match.url}/step1`} component={ResetPasswordStep1} />
                        <Route exact path={`${match.url}/step2`} component={ResetPasswordStep2} />
                        <Route exact path={`${match.url}/success`} component={ResetPasswordSuccess} />
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(ResetPassword)