import React from 'react';
import Banner from '../commonComponents/banner';
import { translations } from '../../utils/translations';

const UnsupportedBrowserBanner = (props) => {
  const description = translations.formatString(
    translations.unsupportedBrowserDes,
    <a href="https://www.hexagonmi.com/products/software/sfx-solutions/hxgn-sfx-asset-management/what-is-hxgn-sfx-asset-management#specifications" target="_blank" rel="noopener noreferrer">
      {translations.here}
    </a>
  )

  return (
    <Banner
      className={'banner--top'}
      isShow={true}
      showClose={false}
      title={translations.unsupportedBrowserTitle}
      description={description}
      bannerPosition={'top'}
      bannerType={'warning'}
    />
  )
}

export default UnsupportedBrowserBanner;