import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { FormGroup, Input, Button, Label, Spinner } from 'reactstrap';
import { debounce } from 'lodash'

import styles from '../registry.module.scss';
import Step from '../../commonComponents/step';
import { Select } from '@sfx/react-ui';
import registerData from '../register-data';
import { getCountryPhoneCodeListAsyc as getCountryPhoneCodeListAsync, getCountryListAsync, getStateListAsync, validateOrgNameAsync } from '../register.service'
import { validatePhoneNumber } from '../../../utils/formValidation';
import Alert from '../../commonComponents/alert';

class CreateAccountOrganization extends Component {
    state = {
        countryList: [],
        countryPhoneCodeList: [],
        stateList: [],
        cityList: [],
        cityChangeAble: false,
        organizationName: registerData.organizationName || '',
        isShowCheckOrgNameLoading: false,
        validateOrgName: false,
        showOrgError: false,
        validateOrgNameMsg: '',
        organizationPhoneCountryCode: registerData.organizationPhoneCountryCode || null,
        organizationPhoneNumber: registerData.organizationPhoneNumber || '',
        organizationAddress: registerData.organizationAddress || '',
        isOrganizationAddressChange: false,
        country: registerData.organizationCountry || null,
        organizationZipCode: registerData.organizationZipCode || '',
        isOrganizationZipCodeChange: false,
        organizationCity: registerData.organizationCity || '',
        isOrganizationCityChange: false,
        state: registerData.organizationState || null,
        organizationAddressLine: registerData.organizationAddressLine || '',
        stateChangeAble: false,
        validatePhoneNum: false,
        showPhoneError: false,
        validatephoneMsg: '',
        showProvinceLoading: false,
        showCountryLoading: false,
        showNumberLoading: false
    }

    componentDidMount() {
        this.getCountryPhoneCodeListAsync();
        this.getCountryListAsync();
        if (registerData.organizationCountry) {
            this.getStateListAsync(registerData.organizationCountry.geonameId);
        }
        this.validForm();
    }

    validForm = () => {
        if (registerData.organizationPhoneNumber) {
            this.validatePhone(registerData.organizationPhoneNumber);
        }
        if (registerData.organizationName) {
            this.validateOrganizationName(registerData.organizationName);
        }
    }

    getCountryPhoneCodeListAsync = async () => {
        this.setState({ showNumberLoading: true })
        try {
            const res = await getCountryPhoneCodeListAsync({ catchError: true });
            if (res && res.data) {
                this.setState({ countryPhoneCodeList: res.data, showNumberLoading: false });
            }
        } catch (err) {
            this.setState({ showNumberLoading: false })
        }
    }

    getCountryListAsync = async () => {
        this.setState({ showCountryLoading: true })
        try {
            const res = await getCountryListAsync({ catchError: true });
            if (res && res.data && res.data.length) {
                this.setState({ countryList: res.data, showCountryLoading: false });
            }
        } catch (err) {
            this.setState({ showCountryLoading: false })
        }
    }

    getStateListAsync = async (countryId) => {
        this.setState({ showProvinceLoading: true })
        try {
            const res = await getStateListAsync(countryId, { catchError: true });
            if (res && res.data && res.data.length) {
                this.setState({ stateList: res.data, stateChangeAble: true, showProvinceLoading: false });
            }
        } catch (err) {
            this.setState({ showProvinceLoading: false })
        }

    }

    onOrganizationNameChange = (e) => {
        const value = e.target.value;
        this.setState({ organizationName: value, validateOrgName: false });
        value.trim() && this.validateOrganizationName(value.trim());
    }

    validateOrganizationName = debounce(async (value) => {
        this.setState({ isShowCheckOrgNameLoading: !!this.state.organizationName })
        try {
            let { data } = await validateOrgNameAsync(value, { catchError: true });
            let validateOrgNameMsg = data && (<>An SFx account with this organization name already exists. If you think you belong to this account, please ask your account administrator for an invitation to join it. Otherwise, to create new account, please select a different organization name.</>)
            this.setState({
                validateOrgName: !data, validateOrgNameMsg, isShowCheckOrgNameLoading: false, showOrgError: data
            })
        } catch (error) {
            if (error.response && error.response.status === 404) {
                this.setState({
                    validateOrgName: true, validateOrgNameMsg: '', isShowCheckOrgNameLoading: false, showOrgError: false
                })
            } else {
                this.setState({
                    validateOrgName: false, validateOrgNameMsg: 'network wrong', isShowCheckOrgNameLoading: false, showOrgError: true
                })
            }

        }
    }, 1000)

    onOrganizationPhoneNumberChange = (e) => {
        const value = e.target.value;
        registerData.organizationPhoneNumber = value;
        this.setState({ organizationPhoneNumber: value, validatePhoneNum: false }, () => {
            value && this.validatePhone(value);
        });
    }

    validatePhone = debounce((value) => {
        let msg;
        const validateRes = validatePhoneNumber(value);
        const msgDom = (<>Please only use <span className={styles.textcolor}>+</span>, <span className={styles.textcolor}>-</span>, <span className={styles.textcolor}>( )</span>,<span className={styles.textcolor}>.</span> , and  <span className={styles.textcolor}>spaces</span> to divide phone numbers in this section.</>)
        if (validateRes.type === 'special') {

            msg = <Alert type='warning' message={msgDom} />;
        } else {
            msg = (value && validateRes.message && < Alert type='warning' message={msgDom} />);
        }

        if (validateRes.valid) {
            registerData.organizationPhoneNumber = value;
        }

        this.setState({ validatephoneMsg: msg, validatePhoneNum: validateRes.valid, showPhoneError: !validateRes.valid });
    }, 1000)


    onOrganizationAddressChange = (e) => {
        const value = e.target.value;
        this.setState({ organizationAddress: value, isOrganizationAddressChange: true });

    }

    onOrganizationAddressLineChange = (e) => {
        const value = e.target.value;
        this.setState({ organizationAddressLine: value })
    }

    onOrganizationZipCodeChange = (e) => {
        const value = e.target.value;
        this.setState({ organizationZipCode: value, isOrganizationZipCodeChange: true });
    }

    onOrganizationCityChange = (e) => {
        const value = e.target.value;
        this.setState({ organizationCity: value, isOrganizationCityChange: true });
    }


    onNext = () => {
        this.props.onStepChange(3);
        this.setRegisterData();

    }

    onBack = () => {
        this.props.onStepChange(1);
        this.setRegisterData();
    }

    setRegisterData = () => {
        registerData.organizationName = this.state.organizationName.trim();
        registerData.organizationAddress = this.state.organizationAddress.trim();
        registerData.organizationAddressLine = this.state.organizationAddressLine.trim();
        registerData.organizationZipCode = this.state.organizationZipCode.trim();
        registerData.organizationCity = this.state.organizationCity.trim();
    }

    onChangePhoneCountryCode = (item) => {
        this.setState({ organizationPhoneCountryCode: item }, () => {
            registerData.organizationPhoneCountryCode = item;
        });

    }

    onCountryChange = (item) => {
        this.setState({ country: item, state: null, stateChangeAble: false }, () => {
            registerData.organizationCountry = item;
            this.getStateListAsync(item.geonameId);
        });
    }

    onStateChange = (item) => {
        this.setState({ state: item }, () => {
            registerData.organizationState = item;
        });
    }


    isFormValid = () => {

        return this.state.organizationName.trim()
            && this.state.validateOrgName
            && this.state.organizationPhoneCountryCode
            && this.state.validatePhoneNum
            && this.state.organizationAddress.trim()
            && this.state.country
            && this.state.organizationZipCode.trim()
            && this.state.organizationCity
            && this.state.organizationCity.trim();
        // && this.state.state

    }

    render() {
        const nextDisabled = !this.isFormValid();
        return (
            <React.Fragment>
                <div className={styles.stepWrap}>
                    <Step stepTitle={'Create your Organization'} stepWidth={320} stepNum={4} stepActive={2} stepActiveText={<span>Tell us about where<font style={{ fontWeight: 'bold' }}> you work.</font> </span>} />
                </div>
                <div className={styles.row}>
                    <FormGroup className={styles.formGroupWrap}>
                        <Label for="organizationName" className={styles.label}>Organization Name
                            {!this.state.organizationName.trim() && <span className="require">*</span>}
                        </Label>
                        {this.state.isShowCheckOrgNameLoading && <Spinner className={styles.spinner} size="sm" color="#fff" />}
                        <Input defaultValue={registerData.organizationName || ''} value={this.state.organizationName} onChange={this.onOrganizationNameChange} />
                        {this.state.validateOrgNameMsg && <Alert type='warning' message={this.state.validateOrgNameMsg} />}
                    </FormGroup>

                    <FormGroup>
                        <Label for="phoneCountryCode" className={styles.label}>Phone Country Code
                        {!this.state.organizationPhoneCountryCode && <span className="require">*</span>}
                        </Label>
                        <Select
                            placeholder={''}
                            showFlag={true}
                            renderOption={i => ({
                                label: `(+${i.code} ${i.name} )`,
                                value: i.geonameId,
                                FlagUrl: i.flagUrl
                            })}
                            isDisabled={this.state.showNumberLoading}
                            isLoading={this.state.showNumberLoading}
                            value={this.state.organizationPhoneCountryCode}
                            options={this.state.countryPhoneCodeList}
                            onChange={this.onChangePhoneCountryCode} />
                    </FormGroup>

                    <FormGroup>
                        <Label for="organizationPhoneNumber" className={styles.label}>Organization Phone Number
                            {!this.state.organizationPhoneNumber.trim() && <span className="require">*</span>}
                        </Label>
                        <Input defaultValue={registerData.organizationPhoneNumber || ''} value={this.state.organizationPhoneNumber} onChange={this.onOrganizationPhoneNumberChange} maxLength='20' />
                        {this.state.validatephoneMsg}
                    </FormGroup>
                    <FormGroup>
                        <Label for="organizationAddress " className={styles.label}>Organization Address
                            {!this.state.organizationAddress.trim() && <span className="require">*</span>}
                        </Label>
                        <Input defaultValue={registerData.organizationAddress || ''} value={this.state.organizationAddress} onChange={this.onOrganizationAddressChange} maxLength='50' />
                    </FormGroup>
                    <FormGroup>
                        <Label for="addressLine" className={styles.label}>Address Line 2 (Optional)
                        </Label>
                        <Input defaultValue={registerData.organizationAddressLine || ''} value={this.state.organizationAddressLine} onChange={this.onOrganizationAddressLineChange} maxLength='50' />
                    </FormGroup>
                    <FormGroup>
                        <Label for="Country" className={styles.label}>Country
                        {!this.state.country && <span className="require">*</span>}
                        </Label>
                        <Select
                            placeholder={''}
                            showFlag={true}
                            renderOption={i => ({
                                label: i.countryName,
                                value: i.geonameId,
                                FlagUrl: i.flagUrl
                            })}
                            isDisabled={this.state.showCountryLoading}
                            isLoading={this.state.showCountryLoading}
                            value={this.state.country}
                            options={this.state.countryList}
                            onChange={this.onCountryChange} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="State" className={styles.label}>State / Province
                        {/* {!this.state.state && <span className="require">*</span>} */}
                        </Label>
                        <Select
                            placeholder={''}
                            renderOption={i => ({ label: i.name, value: i.geonameId })}
                            isLoading={this.state.showProvinceLoading}
                            isDisabled={!this.state.stateChangeAble}
                            value={this.state.state}
                            options={this.state.stateList}
                            onChange={this.onStateChange} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="city" className={styles.label}>City
                        {!this.state.organizationCity.trim() && <span className="require">*</span>}
                        </Label>
                        <Input defaultValue={registerData.organizationCity || ''} onChange={this.onOrganizationCityChange} maxLength='50' />
                    </FormGroup>
                    <FormGroup>
                        <Label for="zipCode" className={styles.label}>Zip Code / Postal Code
                        {!this.state.organizationZipCode.trim() && <span className="require">*</span>}
                        </Label>
                        <Input defaultValue={registerData.organizationZipCode || ''} value={this.state.organizationZipCode} onChange={this.onOrganizationZipCodeChange} maxLength='50' />
                    </FormGroup>
                    <div className={styles.buttonRight}>
                        <Button onClick={this.onBack} outline size='sm'>Back</Button>
                        <Button disabled={nextDisabled} onClick={this.onNext} color="primary" size='sm'>Review Details</Button>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(CreateAccountOrganization);