import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';

import withLocalization from '../hoc/with-localization.component';
import LandingPage from './landingPage.component'
import { UserContext } from '../userProvider'
class Home extends Component {
    render() {
        let { name, solutions, solutionLoading } = this.context
        solutions = solutions.map(i => {
            let images = i.images && i.images[0]
            let defaultImageUrl = i.defaultImageUrl
            let solutionImgUrl = images && images.url ? images.url : defaultImageUrl
            i.solutionImgUrl = solutionImgUrl
            return i
        })

        return (
            <React.Fragment>
                <LandingPage solutions={solutions} userName={name} solutionLoading={solutionLoading} />
            </React.Fragment >
        )
    }
}
Home.contextType = UserContext
export default withRouter(withLocalization(Home));