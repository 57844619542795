import React, { Component } from 'react'
import styles from '../registry.module.scss';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';

export default class SendNotificationSuccess extends Component {
    state = {
        persistedState: {}
    }

    goToPrevious = () => {
        this.props.onStepChange(1, '', this.state.persistedState);
    }

    componentDidMount() {
        let persistedState = this.props.persistedState;
        this.setState({ persistedState: persistedState })
    }

    onSignInButtonClick = () => {
        const returnURL = localStorage.getItem('IDS_RETURNURL');
        if (returnURL) {
            window.location.replace(`/Account/Login${returnURL}`)
        } else {
            window.location = '/Account/Login';
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className={styles.headerTextCenter}>Thank you!</div>
                <div className={styles.guide}>{this.props.message}</div>
                <div className={styles.buttonGroupMargin2}>
                    <a target="_blank" rel="noopener noreferrer" href='https://www.hexagonsfx.com'>Learn More About SFx</a>
                    <Button onClick={this.onSignInButtonClick} color="primary" size='sm' >Sign in</Button>
                </div>
                <div className={styles.contactSFx}><Link to="#" onClick={this.goToPrevious} >Contact SFx for assistance </Link></div>
            </React.Fragment>
        )
    }
}