import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import styles from './index.module.scss'

export default class ResetPasswordStep2 extends Component {
    render() {
        const returnURL = localStorage.getItem('IDS_RETURNURL')
        return (
            <React.Fragment>
                <div className={styles.header}>Thank you!</div>
                <div className={styles.guide}>If your email account entered is correct, you will receive further instructions via email.</div>
                <div className={styles.buttonGroup}>
                    <Link to={{
                        pathname: '/Account/Login',
                        search: returnURL
                    }}>
                        Return to Sign In
                    </Link>
                </div>
            </React.Fragment>
        )
    }
}