import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Button, FormGroup, Input, CustomInput, Form, Label } from 'reactstrap';

import queryStringHandler from 'query-string';
import userManager from '../../utils/userManage';
import withLocalization from '../hoc/with-localization.component';
import PasswordMask from '../commonComponents/password';
import Alert from '../commonComponents/alert';
import ToolTip from '../commonComponents/toolTip';
import styles from './login.module.scss';
import { validateStringValue } from '../../utils/formValidation';
import { LoginAsync, AcceptTerms } from '../../services/api/account.service';
import Modal from '../commonComponents/modal';
import { IconInfo } from '@sfx/icons';

import { IsEnableUserSelfRegistration, defaultClientId, assetManagerDownloadLink, showLegacySFxDesktopReminder, learnmoreLink, showMigrationLoginReminder } from '../../config';

class Login extends Component {
  constructor() {
    super();
    this.state = {
      loginFailed: false,
      userNameInvalid: false,
      userNameErrorMessage: '',
      passwordInvalid: false,
      passwordErrorMessage: '',
      showErrors: false,
      canSubmit: true,
      userName: '',
      password: '',
      rememberFlag: false,
      message: '',
      returnUrl: '',
      isEnableUserSelfRegistration: false,
      learnmoreLink: '',
      showMigrationLoginReminder: false
    };
  }

  componentDidMount = () => {
    let returnUrl =
          queryStringHandler.parse(this.props.location.search).ReturnUrl || '';
    localStorage.setItem('IDS_RETURNURL', `?ReturnUrl=${encodeURIComponent(returnUrl)}`);
    let clientUrl = queryStringHandler.parse(returnUrl);
    let clientReturnUrl =
      (clientUrl.redirect_uri &&
        clientUrl.redirect_uri
          .replace('/callback', '')
          .replace('/silent', '')) ||
      '';
    localStorage.setItem('clientReturnUrl', clientReturnUrl);
    const client_id = clientUrl['/connect/authorize/callback?client_id'];

    if (client_id) {
      localStorage.setItem('clientId', client_id);
    } else {
      defaultClientId.then(result => {
        localStorage.setItem('clientId', result);
      });
    }

    this.setState({ returnUrl });

    IsEnableUserSelfRegistration().then(result => {
      this.setState({ isEnableUserSelfRegistration: result });
    });

    learnmoreLink.then(result => {
        this.setState({ learnmoreLink: result });
    })

    showMigrationLoginReminder.then(result => {
        this.setState({ showMigrationLoginReminder: result });
    })

    assetManagerDownloadLink.then(result => {
        showLegacySFxDesktopReminder.then(showResult => {
            if (client_id === "SFxConnectorService" && showResult.toLowerCase() === 'true') {
                this.showSfxDesktopDeprecatedModal(result);
            }
         })  
    });
  };
  handleInputChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState(
      {
        [name]: value
      },
      () => {
        this.validateFields();
      }
    );
  };
  validateFields = () => {
    const userNameValidCheck = validateStringValue(
      this.state.userName,
      true,
      1,
      255
    );
    const passwordValidCheck = validateStringValue(
      this.state.password,
      true,
      1,
      255
    );

    const valid = userNameValidCheck.valid && passwordValidCheck.valid;

    this.setState({
      canSubmit: valid,
      userNameInvalid: !userNameValidCheck.valid,
      passwordInvalid: !passwordValidCheck.valid,
      userNameErrorMessage: userNameValidCheck.errorMessage,
      passwordErrorMessage: passwordValidCheck.errorMessage,
      loginFailed: false
    });

    return valid;
    };


    showSfxDesktopDeprecatedModal = (result, onOk, onCancel) => {
        const content = (
            <span>
                There is a new Version available! <br></br>
                The current SFx desktop version is only available for provisioning Calipri devices.<br></br>
                Please visit  {' '}
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={result}
                >
                {' '}
                Metrology Asset Manager
                </a>{' '}
                to download the latest version of Metrology Connector and provision other metrology devices.
            </span>
        );
        Modal.info({
            content,
            okText: 'Ok',
            onOk: () => {
                onOk && onOk();
            },
            onClose: () => {
                onCancel && onCancel();
            }
        });
    };

  showAgreeTermModal = (onOk, onCancel) => {
    const content = (
      <span>
        I have read and agree to the{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.hexagonmi.com/terms"
        >
          {' '}
          Terms and Conditions
        </a>{' '}
        of the Hexagon Manufacturing Intelligence Cloud Services Agreement.
      </span>
    );
    Modal.info({
      content,
      okText: 'Agree',
      onOk: () => {
        onOk && onOk();
      },
      onClose: () => {
        onCancel && onCancel();
      }
    });
  };

  rememberMe = e => {
    this.setState({
      rememberFlag: !this.state.rememberFlag
    });
  };

  login = async e => {
    e.preventDefault();
    const shouldSubmit = this.validateFields();
    this.setState({ showErrors: true, canSubmit: false });
    if (shouldSubmit) {
      const { userName, password, rememberFlag, returnUrl } = this.state;
      let html = (
        <>
          <span>{this.props.translate('loginFailedResponse')}</span>
          <span className={styles.serviceLink}>
            <a href="/ResetPassword/step1">
              {this.props.translate('resetPassword')}
            </a>
          </span>
          <span>{this.props.translate('haveTrouble')}</span>
          <span className={styles.serviceLink}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://support.hexagonmi.com/s/"
            >
              {this.props.translate('loginFailedContact')}
            </a>
          </span>
        </>
      );
      LoginAsync(
        {
          username: userName,
          password,
          rememberLogin: rememberFlag,
          returnUrl
        },
        { catchError: true }
      )
        .then(res => {
          const loginProcess = () => {
            if (res.data.solutions) {
              let solutions = res.data.solutions || [];
              localStorage.setItem('IDS_solutions', JSON.stringify(solutions));
            }

            localStorage.setItem('IDS_Auth', true);
            let IDSRedirectURL = returnUrl;
            if (res.data.isRedirectClient && res.data.redirectClientUri) {
              IDSRedirectURL = res.data.redirectClientUri;
            }
            if (IDSRedirectURL && IDSRedirectURL !== '/') {
              // If there is a returnUrl, then redirect to the returnUrl after login
              window.location.replace(IDSRedirectURL);
            } else {
              // If login the IDS, call OIDC to complete SSO after login
              if (this.props.location.currentLocation) {
                localStorage.setItem(
                  'currentIDSRedirectURL',
                  this.props.location.currentLocation
                );
              }
              userManager.signIn();
            }
          };

          if (res.data.user) {
            if (res.data.user.hasAcceptedTerms) {
              loginProcess();
            } else {
              this.showAgreeTermModal(
                () => {
                  AcceptTerms(res.data.user.id).then(() => {
                    loginProcess();
                  });
                },
                () => {
                  this.setState({
                    showErrors: true,
                    canSubmit: true,
                    loginFailed: false
                  });
                }
              );
            }
          }
        })
        .catch(error => {
          let status =
            error.response && error.response.status
              ? error.response.status
              : '-1';
          let eventResult = error.response.headers['event-result'];
          switch (status) {
            case 403:
              if (eventResult === '0x5') {
                html = (
                  <>
                    <span>
                      Sorry, 20 consecutive logins failed and the account was
                      locked. Please{' '}
                    </span>
                    <span className={styles.serviceLink}>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://support.hexagonmi.com/s/"
                      >
                        contact our customer service
                      </a>
                      .
                    </span>
                  </>
                );
              } else if (eventResult === '0x4') {
                html = (
                  <>
                    <span>{this.props.translate('authorizationFailed')}</span>
                    <span>{this.props.translate('haveTrouble')}</span>
                    <span className={styles.serviceLink}>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://support.hexagonmi.com/s/"
                      >
                        {this.props.translate('loginFailedContact')}
                      </a>
                    </span>
                  </>
                );
              } else if (eventResult === '0x8') {
                html = (
                  <>
                    <span>
                      {this.props.translate('userSubscriptionExpired')}
                    </span>
                    <span>{this.props.translate('haveTrouble')}</span>
                    <span className={styles.serviceLink}>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://support.hexagonmi.com/s/"
                      >
                        {this.props.translate('loginFailedContact')}
                      </a>
                    </span>
                  </>
                );
              } else if (eventResult === '0x9') {
                html = (
                  <>
                    <span>
                      {this.props.translate('userSubscriptionLacked')}
                    </span>
                    <span>{this.props.translate('haveTrouble')}</span>
                    <span className={styles.serviceLink}>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://support.hexagonmi.com/s/"
                      >
                        {this.props.translate('loginFailedContact')}
                      </a>
                    </span>
                  </>
                );
              }

              this.setState({
                loginFailed: true,
                canSubmit: true,
                message: html
              });
              break;
            default:
              this.setState({
                loginFailed: true,
                canSubmit: true,
                message: html
              });
          }
        });
    }
  };

  clickCreateAccountLink = e => {
    if (this.state.returnUrl) {
      localStorage.setItem(
        'IDS_RETURNURL',
        `?ReturnUrl=${encodeURIComponent(this.state.returnUrl)}`
      );
    }
    this.props.history.push('/Register/start');
  };

  render() {
    const prefixCls = 'IS-';
    const content = (
      <>
        Selecting "Keep me signed in" reduces the number of times you are asked
        to sign in on this device.
        <br />
        <br />
        Use this option only on a secure device.
      </>
    );

    return (
      <React.Fragment>
        <div className={`${styles['account-Container']}`}>
          <div className={styles.CarouselWrap}>
            <div className={styles.CarouselWrapMask}></div>
          </div>
          <div className={styles.rightContentBac}></div>
          <div className={styles.rightContent}>
            <div className="text-center">
              <img
                className={styles.img}
                src="/images/Hexagon_RGB_REVERSED.png"
                alt="LOGO"
              />
            </div>
            <h3 className="text-center">Sign In </h3>
            <Form onSubmit={this.login}>
              <FormGroup>
                <Label for="userName">
                  Email
                  {this.state.showErrors && this.state.userNameInvalid && (
                    <span className="require">*</span>
                  )}
                </Label>
                <Input
                  autoFocus="autoFocus"
                  type="text"
                  name="userName"
                  id="userName"
                  value={this.state.userName}
                  className={`${prefixCls}input`}
                  onChange={this.handleInputChange}
                />
              </FormGroup>

              <FormGroup>
                <Label for="password">
                  Password
                  {this.state.showErrors && this.state.passwordInvalid && (
                    <span className="require">*</span>
                  )}
                </Label>
                <PasswordMask
                  fieldName="password"
                  className={`${prefixCls}input`}
                  placeHolder=" "
                  onChange={e => this.handleInputChange(e)}
                  errorMessage={this.state.passwordErrorMessage}
                />
              </FormGroup>

              <div className={`${styles['button-group']}`}>
                <div className={styles.remember}>
                  <CustomInput
                    type="checkbox"
                    name="rememberMe"
                    id="rememberMe"
                    label="Keep me signed in"
                    className={`${prefixCls}input`}
                    checked={this.state.rememberFlag}
                    onChange={this.rememberMe}
                  />
                  <ToolTip width={'388px'} content={content}>
                    <span>
                      <IconInfo className={`${styles['help-icon']}`} />
                    </span>
                  </ToolTip>
                </div>
                <Button
                  type="submit"
                  color="primary"
                  size="sm"
                  disabled={!this.state.canSubmit}
                >
                  Sign In
                </Button>
              </div>
              <Link to="/ResetPassword/step1">Reset Password</Link>
              {this.state.showMigrationLoginReminder &&
                (<div className={styles.nexusMigrationTips} >
                    <div>
                        <div className={styles.imgdiv}>
                            <img src="../images/alert-outlinesvg.svg" alt="ico" className={styles.alterImg} />
                        </div>
                        <div className={styles.attention}>Attention</div>
                        <div className={styles.learnmore}>
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={this.state.learnmoreLink} >Learn More
                            </a>
                        </div>
                    </div>
                    <div>SFx Asset Management has moved to Nexus</div>
                </div>)}  
              {this.state.loginFailed && (
                <Alert type="warning" message={this.state.message} />
              )}
            </Form>

            {this.state.isEnableUserSelfRegistration && (
              <div className={`${styles.footerBox} text-center`}>
                <p className={styles.font16}>
                  New to SFx? Create a free account.
                </p>
                <span
                  className={styles.serviceLink}
                  onClick={this.clickCreateAccountLink}
                >
                  Create an account
                </span>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(withLocalization(Login));
