import React, { lazy } from 'react'

import Home from '../components/home'
import Register from '../components/register'
import ConfirmEmail from '../components/register/confirm-email.component'
import Login from '../components/login'
import ResetPassword from '../components/resetPassword'
import Contact from '../components/contact'
import Logout from '../components/logout'
import { SigninCallback, SilentRedirectCallback } from '../components/callback'
import Error from '../components/error';

const routes = [
    {
        path: '/Register',
        component: () => <Register />
    },
    {
        path: '/ConfirmEmail',
        component: () => <ConfirmEmail />
    },
    {
        path: '/Account/Login',
        component: () => <Login />
    },
    {
        path: '/ResetPassword',
        component: () => <ResetPassword />
    },
    {
        path: '/Contact',
        component: () => <Contact />
    },
    {
        path: '/Account/Logout',
        component: () => <Logout />
    },
    {
        path: '/home',
        requiresAuth: true, // need to login first 
        component: () => <Home />
    },
    {
        path: '/accountSettings/:activeMenu?',
        requiresAuth: true, // need to login first 
        component: lazy(() => import('../components/accountSettings'))
    },
    {
        path: '/userPreferences',
        requiresAuth: true,
        component: lazy(() => import('../components/userPreferences'))
    },
    {
        path: '/callback',
        component: SigninCallback
    }, {
        path: '/silent',
        component: SilentRedirectCallback
    }, {
        path: '/error',
        component: Error
    }
]

export default routes