import React, { Component } from 'react';
import { Alert, Container, Button, FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import withLocalization from '../hoc/with-localization.component';
// import { callWrapper } from '../../services/api.service';
import styles from './contactUs.module.scss';
import ContactRight from './contactRight.component';
import { validateStringValue } from '../../utils/formValidation';

class ContactUs extends Component {
  constructor(props) {
    super(props)

    this.state = {
      userFirstName: '',
      userFirstNameValid: false,
      userFirstNameError: '',
      userLastName: '',
      userLastNameValid: false,
      userLastNameError: '',
      subject: '',
      subjectValid: false,
      subjectError: '',
      message: '',
      messageValid: false,
      messageError: '',
      showErrors: false,
      visible: false,
      promptMessage: '',
      alertType: '',
    }
  }

  resetForm = () => {
    this.setState({
      userFirstName: '',
      userFirstNameValid: false,
      userFirstNameError: '',
      userLastName: '',
      userLastNameValid: false,
      userLastNameError: '',
      subject: '',
      subjectValid: false,
      subjectError: '',
      message: '',
      messageValid: false,
      messageError: '',
      showErrors: false,
      visible: false,
      promptMessage: '',
      alertType: '',
    })
  }

  onFirstNameChange = (e) => {
    const val = e.target.value;
    this.setState({
      userFirstName: val
    }, () => { this.validateFirstName(); })
  }

  validateFirstName = () => {
    const firstNameValidCheck = validateStringValue(this.state.userFirstName, true, 1, 255);
    this.setState({
      userFirstNameValid: firstNameValidCheck.valid,
      userFirstNameError: firstNameValidCheck.errorMessage
    });

    return firstNameValidCheck.valid;
  }

  onLastNameChange = (e) => {
    const val = e.target.value;
    this.setState({
      userLastName: val
    }, () => { this.validateLastName(); })
  }

  validateLastName = () => {
    const lastNameValidCheck = validateStringValue(this.state.userLastName, true, 1, 255);
    this.setState({
      userLastNameValid: lastNameValidCheck.valid,
      userLastNameError: lastNameValidCheck.errorMessage
    });

    return lastNameValidCheck.valid;
  }

  onSubjectChange = (e) => {
    const val = e.target.value;
    this.setState({
      subject: val
    }, () => { this.validateSubject(); })
  }

  validateSubject = () => {
    const subjectValidCheck = validateStringValue(this.state.subject, true, 1, 255);
    this.setState({
      subjectValid: subjectValidCheck.valid,
      subjectError: subjectValidCheck.errorMessage
    });

    return subjectValidCheck.valid;
  }

  onMessageChange = (e) => {
    const val = e.target.value;
    this.setState({
      message: val
    }, () => { this.validateMessage(); })
  }

  validateMessage = () => {
    const messageValidCheck = validateStringValue(this.state.message, true, 1, 4000);
    this.setState({
      messageValid: messageValidCheck.valid,
      messageError: messageValidCheck.errorMessage
    });

    return messageValidCheck.valid;
  }

  handleSubmit = (e) => {
    // e.preventDefault()
    // this.setState({ showErrors: true });
    // const validFirstName = this.validateFirstName();
    // const validLastName = this.validateLastName();
    // const validSubject = this.validateSubject();
    // const validMessage = this.validateMessage();

    // if (validFirstName && validLastName && validSubject && validMessage) {
    //   const formData = {
    //     FirstName: this.state.userFirstName,
    //     LastName: this.state.userLastName,
    //     Subject: this.state.subject,
    //     Message: this.state.message,
    //   }
    //   //todo: use real API replace below fake API
    //   callWrapper('/api/contact/AddContact', true, '', 'post', JSON.stringify(formData))
    //     .then(res => {
    //       this.setState({
    //         visible: true,
    //         alertType: 'sucess',
    //         promptMessage: 'Contact info sent successfully',
    //       });
    //       this.resetForm();
    //     })
    //     .catch(error => {
    //       this.setState({
    //         visible: true,
    //         alertType: 'danger',
    //         promptMessage: 'Contact info sent failed',
    //       });
    //     })
    // }
  };

  onDismiss = () => {
    this.setState({ visible: false });
  }

  render() {
    return (
      <Container>
        <form className={styles.contactForm} onSubmit={this.handleSubmit}>
          <div className={`form-row ml-0 ${styles.contactTitle}`}>
            <h6><strong className="text-uppercase">{this.props.translate('contact')}</strong></h6>
          </div>
          <div name="divContent" className="form-row">
            {/* Contact Left */}
            <div name="ContactLeft" className="col-md-6">
              <Alert color={this.state.alertType} isOpen={this.state.visible} toggle={this.onDismiss}>
                {this.state.promptMessage}
              </Alert>
              <div className="form-row col-md-12 pl-0 pr-0">
                <FormGroup className="col-md-6">
                  <Label className={styles.contactLabel} for="userFirstname">{this.props.translate('firstName')}</Label>
                  <Input
                    type="text"
                    name="userFirstName"
                    id="userFirstName"
                    className={styles.contactControl}
                    value={this.state.userFirstName}
                    placeholder={this.props.translate('enterFirstName')}
                    onChange={this.onFirstNameChange}
                    invalid={this.state.showErrors && !this.state.userFirstNameValid} />
                  {this.state.showErrors && !this.state.userFirstNameValid &&
                    <FormFeedback>{this.state.userFirstNameError}</FormFeedback>
                  }
                </FormGroup>
                <FormGroup className="col-md-6 pr-0 mr-0">
                  <Label className={styles.contactLabel} for="userLastname">{this.props.translate('lastName')}</Label>
                  <Input
                    type="text"
                    name="userLastName"
                    id="userLastName"
                    className={styles.contactControl}
                    value={this.state.userLastName}
                    placeholder={this.props.translate('enterLastName')}
                    onChange={this.onLastNameChange}
                    invalid={this.state.showErrors && !this.state.userLastNameValid} />
                  {this.state.showErrors && !this.state.userLastNameValid &&
                    <FormFeedback>{this.state.userLastNameError}</FormFeedback>
                  }
                </FormGroup>
              </div>
              <div className="form-row">
                <FormGroup className="col-md-12">
                  <Label className={styles.contactLabel} for="subject">{this.props.translate('subject')}</Label>
                  <Input
                    type="text"
                    name="subject"
                    id="subject"
                    className={styles.contactControl}
                    value={this.state.subject}
                    placeholder={this.props.translate('enterSubject')}
                    onChange={this.onSubjectChange}
                    invalid={this.state.showErrors && !this.state.subjectValid} />
                  {this.state.showErrors && !this.state.subjectValid &&
                    <FormFeedback>{this.state.subjectError}</FormFeedback>
                  }
                </FormGroup>
              </div>
              <div className="form-row">
                <FormGroup className="col-md-12">
                  <Label className={styles.contactLabel} for="message">{this.props.translate('message')}</Label>
                  <Input
                    type="textarea"
                    rows="6"
                    name="message"
                    id="message"
                    value={this.state.message}
                    className={styles.contactControl}
                    placeholder={this.props.translate('enterMessage')}
                    onChange={this.onMessageChange}
                    invalid={this.state.showErrors && !this.state.messageValid} />
                  {this.state.showErrors && !this.state.messageValid &&
                    <FormFeedback>{this.state.messageError}</FormFeedback>
                  }
                </FormGroup>
              </div>
              <div className="form-row">
                <FormGroup className="col-md-12">
                  <Button type="submit" color="primary" size="sm">{this.props.translate('submit')}</Button>
                </FormGroup>
              </div>
            </div>

            {/* Vertical Separator Line */}
            <div name="verticalSeparatorLine" className={`col-md-1 ${styles.verticalSeparatorLine}`}></div>

            {/* Contact Right */}
            <ContactRight props={this.props} />
          </div>
        </form>
      </Container>
    );
  }
}

export default withRouter(withLocalization(ContactUs));