import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input, FormText } from 'reactstrap';
import classnames from 'classnames';

class PasswordMask extends Component {
	state = {
		masked: true,
		passwordValue: this.props.value || ''
	}

	handleEyeClick = () => {
		this.setState({ masked: !this.state.masked });
	}

	handlePasswordChange = (e) => {
		e.persist();
		const val = e.target.value.trim();
		this.setState({ passwordValue: val },
			() => {
				if (this.props.onChange) {
					// return event so this param can be used to debounce
					this.props.onChange(e);
				}
			})
	}

	handlePasswordBlur(e) {
		const val = e.target.value;
		if (this.props.onBlur) {
			this.props.onBlur(val)
		}
	}

	render() {
		const innerEye = (
			<span onClick={this.handleEyeClick} className={classnames(this.props.className, { "input-group-text": this.props.bootstrapDefaultStyle }, { "outline-0 px-3 d-flex align-items-center": !this.props.bootstrapDefaultStyle })}>
				{this.state.masked &&
					<img style={{ width: '16px' }} src='/images/eye.svg' alt='eye' />
				}
				{!this.state.masked &&
					<img style={{ width: '16px' }} src='/images/eyeSlash.svg' alt='eyeSlash' />
				}
			</span>
		);
		return (
			<React.Fragment>
				<div className={classnames(this.props.className, { "input-group": this.props.bootstrapDefaultStyle }, { "d-flex align-items-center": !this.props.bootstrapDefaultStyle })} id="show_hide_password">

					<Input type={this.state.masked ? "password" : "text"} className={classnames(this.props.className, { "outline-0": !this.props.bootstrapDefaultStyle })}
						name={this.props.fieldName}
						placeholder={this.props.placeHolder}
						data-toggle="password"
						defaultValue={this.state.passwordValue}
						onChange={this.handlePasswordChange}
						onBlur={this.handlePasswordBlur.bind(this)}
						invalid={this.props.invalid}
					/>
					{this.props.bootstrapDefaultStyle &&
						<div className="input-group-append">{innerEye}</div>
					}
					{!this.props.bootstrapDefaultStyle &&
						<React.Fragment>{innerEye}</React.Fragment>
					}
				</div>
				{this.props.showErrorMsg && <FormText>{this.props.errorMessage}</FormText>}
			</React.Fragment>
		)
	}
}

PasswordMask.propTypes = {
	fieldName: PropTypes.string.isRequired,
	invalid: PropTypes.bool,
	handleChange: PropTypes.func,
	className: PropTypes.string,
	bootstrapDefaultStyle: PropTypes.bool,
	errorMessage: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.element,
	]),
	placeHolder: PropTypes.string
};

PasswordMask.defaultProps = {
	className: '',
	bootstrapDefaultStyle: true,
	placeHolder: 'Password'
}

export default PasswordMask;