import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import queryStringHandler from 'query-string';
import styles from '../../styles/account.module.scss';
import withLocalization from '../hoc/with-localization.component';
import { LogoutAsync } from '../../services/api/account.service';
class Logout extends Component {

    constructor() {
        super();
        this.state = {
            redirectUrl: '',
            messageIndex: 'logingout'
        }
    }
    componentDidMount() {
        this.signOut()
    }
    signOut = async () => {
        let originalQueryString = queryStringHandler.parse(this.props.location.search);
        let logoutId = originalQueryString.logoutId;
        let logoutModel = {
            logoutId: logoutId
        };
        LogoutAsync(logoutModel, { catchError: true }).then(res => {
            if (res) {
                localStorage.removeItem('IDS_Auth')
                this.setState({
                    redirectUrl: res.headers["redirect-url"],
                    messageIndex: 'logged'
                }, () => {
                    if (logoutId && res.headers["redirect-url"]) {
                       window.location = res.headers["redirect-url"];
                    }
                });
            }

        }).catch(error => {
            let status = error.response && error.response.status ? error.response.status : '-1'
            switch (status) {
                case 401:
                    localStorage.removeItem('IDS_Auth')
                    this.setState({
                        messageIndex: 'logged'
                    });
                    break;
                default:
                    return;
            }
        })
    };
    render() {
        const message = {
            logingout: {
                title: 'Logging Out',
                content: 'You are being logging out, please wait ...'
            },
            logged: {
                title: 'Logout',
                content: this.state.redirectUrl ? 'You have been logged out.' : 'You have been logged out.'
            }
        }
        return (
            <div>
                {this.state.messageIndex === 'logged' && !this.state.redirectUrl && <div className={`${styles['account-Container']}`}>
                    <img src="/images/Hexagon_RGB_REVERSED.png" alt="LOGO" />
                    <h3>{message[this.state.messageIndex]['title']}</h3>
                    <p className={`${styles['logout-message']}`}>{message[this.state.messageIndex]['content']}</p>
                    <div>
                        <a href='/Account/Login' className={`${styles.right}`}>Return to Sign in</a>
                    </div>
                </div >}
            </div>
        )
    }

}

export default withRouter(withLocalization(Logout))

