import React, { useState, useEffect, useRef, useCallback, useContext } from 'react';
import {
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
} from 'reactstrap';
import { Spin } from '@sfx/react-ui'
import { UserContext } from '../userProvider'
import { getSourceAsync } from '../../services/api/account.service'
import styles from './landingPage.module.scss'
import cx from 'classnames';

const Category = {
    Recommended: 'Recommended',
    Carousel: 'Carousel',
    Link: 'Link'
}

const CarouselBox = ({ carouselSourceList, sourceLoading }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === carouselSourceList.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    }

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? carouselSourceList.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    }

    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    }
    const CarouselContent = item => <div className={styles.carouselBox}>
        <div className={cx(styles.carouselSingle, "carousel")} style={{ backgroundImage: `url(${item.imageUrl})` }}></div>
        <div className={styles.rightDetail}>
            <p title={item.title}>{item.title}</p>
            <p>{item.description1}</p>
            <a target="_blank" rel="noopener noreferrer" href={item.url}>
                <span className={styles.rightButton}>LEARN MORE</span>
            </a>
        </div>
    </div>
    const slides = carouselSourceList.map((item) => {
        return (
            <CarouselItem
                className={styles.carousel}
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
                key={`carousel${item.id}`}
            >
                <CarouselContent  {...item} />
            </CarouselItem>
        );
    });

    const carouselDom = () => (carouselSourceList.length > 1 ?
        <Carousel
            activeIndex={activeIndex}
            next={next}
            previous={previous}
            ride={"carousel"}
            interval={8000}
        >
            <CarouselIndicators items={carouselSourceList} activeIndex={activeIndex} onClickHandler={goToIndex} />
            {slides}
            <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
            <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
        </Carousel>
        : <CarouselContent  {...carouselSourceList[0]} />)

    return (
        sourceLoading ?
            <Spin size='0.2' /> : carouselDom()
    );
}
const SolutionCard = (i) => {
    const defaultSRC = '/images/Hexagon_RGB_REVERSED_ICON.png'
    const backgroundImage = i.thumbnailUrl ? { backgroundImage: `url(${i.thumbnailUrl})`} : { backgroundImage: 'url(/images/landing-page-style-guide-1.png)' }
    
    return (
        <figure className={styles.solutionCard}>
            <a className={styles.solutionImgWrap} style={backgroundImage} href={i.clientUri} target="_blank" rel="noopener noreferrer">
                <img
                    alt="solutionIcon"
                    src={i.solutionImgUrl || defaultSRC}
                    onError={(e) => { e.target.onError = null; e.target.src = defaultSRC; }} />
            </a>
            <figcaption>
                <div>
                    <p title={i.fullName || i.name}>{i.fullName || i.name}</p>
                    <p className={styles.solutionCardDetail} style={{ display: "none" }}>
                        There are Subscriptions that can be upgraded to PRO version.
                    </p>
                </div>
                <span className={styles.solutionCardButton} style={{ display: "none" }}>Upgrade To PRO</span>
            </figcaption>
        </figure>
    )
}

const SolutionCardList = ({ solutions = [], solutionLoading }) => {

    const solutionContainer = useRef(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const [pageSize, setPageSize] = useState(4);
    const [solutionsList, setSolutionsList] = useState({})

    const getSolutionListCallback = useCallback(() => {
        const solutionsList = {}
        solutions.forEach((i, index) => {
            let pageNo = parseInt(index / pageSize)
            solutionsList[pageNo] = solutionsList[pageNo] ? [...solutionsList[pageNo], i] : [i]
        })
        setSolutionsList(solutionsList)
    }, [pageSize, solutions])

    const pageNoChange = (index) => {
        if (index === Object.keys(solutionsList).length) {
            return;
        }
        setActiveIndex(index)
    }

    useEffect(() => {
        window.addEventListener('resize', updatePageSize)
        updatePageSize()
        return () => {
            window.removeEventListener('resize', updatePageSize)
        }
    }, [])

    const updatePageSize = () => {
        if (!solutionContainer.current) {
            return
        }
        try {
            let windowWidth = window.innerWidth;
            let solutionContainerWidth = solutionContainer.current.clientWidth
            let solutionCardWidth = windowWidth < 780 ? 240 : 200
            let pageSize = parseInt(solutionContainerWidth / solutionCardWidth)
            setPageSize(pageSize <= 1 ? 2 : pageSize)
        } catch (error) {
            setPageSize(4)
        }
    }
    useEffect(() => {
        getSolutionListCallback()
        setActiveIndex(0)
    }, [pageSize, getSolutionListCallback])

    return (
        <div ref={solutionContainer} className={styles.solutionCardWrap}>
            <p className={styles.leftTitle}>My Solutions</p>
            {!solutionLoading &&
                (solutions.length ?
                    <>
                        <div className={styles.solutionCardContainer}>
                            {solutionsList[activeIndex] && solutionsList[activeIndex].map((i, index) => (<SolutionCard key={index} {...i} />))}
                        </div>
                        {
                            Object.keys(solutionsList).length > 1 &&
                            <div className={styles.pagination}>
                                {Object.keys(solutionsList).map((i, index) => (<span key={index} onClick={(e) => pageNoChange(index, e)} className={cx({ [styles.active]: activeIndex === index })}>{index + 1}</span>))}
                                <span className={cx({ [styles.disabled]: activeIndex === Object.keys(solutionsList).length - 1 })} onClick={e => pageNoChange(activeIndex + 1)}></span>
                            </div>
                        }
                    </>
                    : <p className={styles.message}>Seems like you have not been assigned to any Solutions yet, please contact your administrator to assign the Solution&Role to you.</p>
                )}

        </div >
    )
}
const VideoCard = i => {
    return (
        <figure className={styles.videoCard}>
            <a className={styles.videoImgWrap} target="_blank" rel="noopener noreferrer" href={i.url}>
                <div className={styles.videoImg} style={{ backgroundImage: `url(${i.imageUrl})` }}  ></div>
            </a>
            <figcaption>
                <p>{i.title}</p>
            </figcaption>
        </figure>
    )
}
const VideoCardList = ({ videoSourceList, sourceLoading }) => {
    return (!sourceLoading &&
        <div>
            {!!videoSourceList.length && <p className={styles.leftTitle}>Recommended For You</p>}
            <div className={styles.videoCardContainer}>
                {videoSourceList.map((i, index) => <VideoCard {...i} key={index} />)}
            </div>
        </div>)
}
const LandingPage = ({ solutions = [], userName = '', solutionLoading = true }) => {
    const { user } = useContext(UserContext)
    const [videoSourceList, setVideoSourceList] = useState([]);
    const [carouselSourceList, setCarouselSourceList] = useState([]);
    const [linkSource, setLinkSourceList] = useState([]);
    const [sourceLoading, setSourceLoading] = useState(true);
    const initSourceList = (type, data) => {
        let ret = data.filter(i => i.category === type)
        switch (type) {
            case Category.Recommended:
                setVideoSourceList(ret)
                break;
            case Category.Carousel:
                setCarouselSourceList(ret)
                break;
            case Category.Link:
                setLinkSourceList(ret)
                break;
            default:
                break;
        }
    }
    useEffect(() => {
        if (user.profile) {
            getSourceAsync().then(({ data }) => {
                let ret = data.sort((a, b) => (a.order - b.order))
                Object.values(Category).forEach(type => {
                    initSourceList(type, ret)
                })
                setSourceLoading(false)
            }).catch(() => {
                setSourceLoading(false)
            })
        }
        return () => {
        }
    }, [user])

    return (
        <div className={styles.container}>
            <header className={styles.header}>
                <figure>
                    <img className={styles.log} src="/images/Hexagon_RGB_REVERSED.png" alt="LOGO" />
                    <img className={styles.smLog} src="/images/Hexagon_RGB_REVERSED_ICON.png" alt="LOGO" />
                </figure>
                <nav>
                    {!sourceLoading &&
                        <ul className={styles.ul}>
                            {linkSource.map(i => (<li key={i.id}><a href={i.url} target='_blank' rel="noopener noreferrer">{i.title}</a></li>))}
                        </ul>
                    }
                </nav>
            </header>
            <p className={styles.title}>{`Welcome back, ${userName}!`}</p>
            <div className={styles.content}>
                <div className={styles.leftBox}>
                    <SolutionCardList solutions={solutions} solutionLoading={solutionLoading} />
                    <VideoCardList videoSourceList={videoSourceList} sourceLoading={sourceLoading} />
                </div>
                <div className={styles.rightBox}>
                    <CarouselBox carouselSourceList={carouselSourceList} sourceLoading={sourceLoading} />
                </div>
            </div>
        </div>
    )
}
export default LandingPage